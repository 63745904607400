import axiosInstance from "@/axios";

class CCGController {
  async obterDocumentoCCG(tomadorId) {
    return await axiosInstance
      .get(`api/ContraGarantia/BuscarContrato?tomadorId=${tomadorId}`)
      .then(response => response.data.Response);
  }

  async obterPermissoesCCG(modulo) {
    return await axiosInstance
      .get(`api/Permissao/BuscarPermissoes?Modulo=${modulo}`)
      .then(response => response.data.Response.Acao);
  }

  async obterPermissaoAssinatura(contratoId) {
    return await axiosInstance
      .get(
        `api/ContraGarantia/ObterPermissaoAssinatura?contratoId=${contratoId}`
      )
      .then(response => response.data.Response);
  }

  async adicionarParte(data) {
    return await axiosInstance
      .post(`api/ContraGarantia/AdicionarParte`, data)
      .then(response => response.data.Response);
  }

  async adicionarConjuge(data) {
    return await axiosInstance
      .post(`api/ContraGarantia/AdicionarConjuge`, data)
      .then(response => response.data.Response);
  }

  async editarParte(data) {
    return await axiosInstance
      .post(`api/ContraGarantia/AtualizarParte`, data)
      .then(response => response.data.Response);
  }

  async reenviarEmailIndividual(contratoId, parteId) {
    return await axiosInstance
      .post(
        `api/ContraGarantia/ReenviarEmailAssinaturaParte?contratoId=${contratoId}&parteId=${parteId}`
      )
      .then(response => response.data.Response);
  }

  async excluirParte(data) {
    return await axiosInstance
      .delete(`/api/ContraGarantia/RemoverParte`, data)
      .then(response => response.data.Response);
  }

  async reenviarEmailTodos(contratoId) {
    return await axiosInstance
      .post(
        `api/ContraGarantia/ReenviarEmailAssinatura?contratoId=${contratoId}`
      )
      .then(response => response.data.Response);
  }

  async gerarDocumento(contratoId) {
    return await axiosInstance
      .post(`api/ContraGarantia/GerarDocumento?contratoId=${contratoId}`)
      .then(response => response.data.Response);
  }

  async buscarTermoResponsabilidade(tomadorId) {
    return await axiosInstance
      .get(`api/ContraGarantia/BuscarTermo?tomadorId=${tomadorId}`)
      .then(response => response.data.Response);
  }

  async aceitarTermoResponsabilidade(data) {
    return await axiosInstance
      .post(`api/ContraGarantia/AceitarTermo`, data)
      .then(response => response.data.Response);
  }

  async enviarParaAssinatura(contratoId) {
    return await axiosInstance
      .post(`api/ContraGarantia/EnviarParaAssinatura?contratoId=${contratoId}`)
      .then(response => response.data.Response);
  }

  async obterHistoricoAlteracoes(contratoId) {
    return await axiosInstance
      .get(`api/ContraGarantia/ObterLogAuditoria?contratoId=${contratoId}`)
      .then(response => response.data.Response);
  }

  async verificarParteJaFoiCadastradaTomador(contratoId, cpf) {
    return await axiosInstance
      .get(
        `api/ContraGarantia/VerificaParte?contratoId=${contratoId}&cpf=${cpf}`
      )
      .then(response => response.data.Response);
  }

  async cancelarEnvioParaAssinatura(contratoId) {
    return await axiosInstance
      .post(
        `api/ContraGarantia/CancelarEnvioParaAssinatura?contratoId=${contratoId}`
      )
      .then(response => response.data.Response);
  }

  async cancelarCCGAssinado(contratoId) {
    return await axiosInstance
      .post(
        `api/ContraGarantia/CancelarContratoAssinado?contratoId=${contratoId}`
      )
      .then(response => response.data.Response);
  }
}

export default new CCGController();
