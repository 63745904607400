<template>
  <section class="mt-4">
    <div class="table-container">
      <template v-if="listaBoletos.length > 0">
        <vs-table
          class="table-list"
          pagination
          max-items="5"
          :data="listaBoletos"
          noDataText="Nenhum boleto pendente encontrado."
        >
            
          <template slot="thead">
            <vs-th sort-key="documento">Documento</vs-th>
            <vs-th sort-key="noParcela">Parcela</vs-th>
            <vs-th sort-key="vencimento">Vencimento</vs-th>
            <vs-th sort-key="valorParcela">Valor Parcela</vs-th>
            <vs-th sort-key="diasAtraso">Dias Atraso</vs-th>
            <vs-th sort-key="novoVencimento">Novo Vencimento</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].numeroDocumento">
                <span class="font-semibold">{{ data[indextr].numeroDocumento }}</span>
              </vs-td>

              <vs-td :data="data[indextr].numeroParcelas" >
                <span class="font-semibold">{{ data[indextr].numeroParcelas }}</span>
              </vs-td>

              <vs-td :data="data[indextr].vencimento" >
                <span class="font-semibold"> {{ data[indextr].vencimento }}</span>
              </vs-td>

              <vs-td :data="data[indextr].valorParcela" >
                <span class="font-semibold">R$ {{ data[indextr].valorParcela }}</span>
              </vs-td>

              <vs-td :data="data[indextr].diasAtraso" >
                <span class="font-semibold">{{ data[indextr].diasAtraso }}</span>
              </vs-td>

              <vs-td>
                <datepicker :language="language" placeholder="01/01/2020" format="dd/MM/yyyy" v-model="newVencimentoDate"></datepicker>
              </vs-td>

              <vs-td>
                <span class="material-icons">receipt</span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p>Nenhum boleto pendente cadastrado para este tomador</p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import Datepicker from "vuejs-datepicker"; 
import {ptBR} from "vuejs-datepicker/dist/locale";

export default {
  name: "boletos-pendentes",
  components: {Datepicker},
  data () {
    return {
      listaBoletos: [
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "15/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "10",
          novoVencimento: ""
        },
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "13/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "12",
          novoVencimento: ""
        },
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "13/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "12",
          novoVencimento: ""
        },
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "13/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "12",
          novoVencimento: ""
        },
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "13/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "12",
          novoVencimento: ""
        },
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "13/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "12",
          novoVencimento: ""
        },
        {
          numeroDocumento: "699-807-6530/415-412",
          numeroParcelas: "2",
          vencimento: "13/07/2020",
          valorParcela: "10.000,00",
          diasAtraso: "12",
          novoVencimento: ""
        }
      ],
      language: ptBR,
      newVencimentoDate: ""
    };
  }
};
</script>

<style lang="scss" scoped>

.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}


</style>