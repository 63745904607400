<template>
  <div>
    <div class="vx-row">
      <div class="vx-col pt-5 w-full mt-2">
        <vs-collapse
          type="margin"
          v-for="(regra, index) in regrasExecutadas"
          :key="index"
        >
          <vs-collapse-item>
            <div slot="header">
              <p class="text-sm font-semibold"></p>
              <p class="text-lg">
                {{ (regra || {}).EngineExecutionType.Name || "Não informado" }}
              </p>
              <p class="text-sm">
                <b>Executada em </b
                >{{ (regra || {}).CreateDate | moment("DD/MM/YYYY - HH:mm") }}
              </p>
              <vs-button
                class="status"
                type="filled"
                size="small"
                :color="getLabelColor(regra)"
              >
                {{
                  (regra || {}).RuleEngineResult.Success
                    ? "APROVADO"
                    : "REPROVADO"
                }}
              </vs-button>
            </div>

            <div class="vx-row flex">
              <div class="vx-col flex-1">
                <vs-table
                  :data="
                    ((regra || {}).RuleEngineResult || {}).RuleResults || []
                  "
                  noDataText="Nenhum histórico de regras executado."
                >
                  <template slot="thead">
                    <vs-th>
                      Etapa
                    </vs-th>
                    <vs-th>
                      Regra
                    </vs-th>
                    <vs-th>
                      Executada com sucesso?
                    </vs-th>
                    <vs-th>
                      Mensagem
                    </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="indextr">
                        <div class="flex">
                          <span>{{ indextr + 1 }}</span>
                        </div>
                      </vs-td>
                      <vs-td :data="data[indextr].RuleName">
                        <div class="flex">
                          <span>{{ data[indextr].RuleName }}</span>
                        </div>
                      </vs-td>
                      <vs-td :data="data[indextr].Success">
                        <div class="flex">
                          <span>{{
                            data[indextr].Success ? "Sim" : "Não"
                          }}</span>
                        </div>
                      </vs-td>
                      <vs-td :data="data[indextr].Messages">
                        <div class="flex">
                          <span
                            v-if="(data[indextr].Messages || []).length == 0"
                          ></span>
                          <div v-if="(data[indextr].Messages || []).length > 0">
                            <ul>
                              <li
                                v-for="(message, indexMsg) in data[indextr]
                                  .Messages || []"
                                :key="indexMsg"
                              >
                                {{ message }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import axiosInstance from "@/axios";
import _ from "lodash";
export default {
  name: "regras-executadas",
  props: {
    tomador: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      regrasExecutadas: []
    };
  },
  mounted() {
    this.carregarRegras();
  },
  methods: {
    getLabelColor(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "success";
      else return "danger";
    },
    carregarRegras() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/PolicyHolder/GetPolicyHolderEngineExecutionLog?PolicyHolderUniqueId=${this.tomador.UniqueId}`
          )
          .then(response => {
            //this.limitesTotais = response.data.Response
            //this.limites = this.limitesTotais.PolicyHolderLimitsAndRates
            //this.limteDisponivelAprovado = this.limitesTotais.MaxApprovedLimit
            //this.limteTotalAprovado = this.limitesTotais.MaxAvailableLimit

            var data = _.uniqWith(
              response.data.Response,
              (a, b) =>
                a.EngineExecutionType.Name === b.EngineExecutionType.Name ||
                a.CreateDate === b.CreateDate || a.JSONRuleEngineResult ===  b.JSONRuleEngineResult
            );
            this.regrasExecutadas = response.data.Response;
          });
      });
    }
  }
};
</script>
<style>
.vs-collapse-item--icon-header i {
  font-size: 30px;
}
</style>
