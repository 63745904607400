var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-4 py-4" },
    [
      _c("div", { staticClass: "tab" }, [
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto active",
            on: { click: (e) => _vm.openTab(e, "dadosCadastrais") },
          },
          [_vm._v("\n      Dados cadastrais\n    ")]
        ),
        _vm.temGrupoEconomico
          ? _c(
              "button",
              {
                staticClass: "tabLinks cursor-pointer font-medium w-auto",
                on: { click: (e) => _vm.openTab(e, "grupoEconomico") },
              },
              [_vm._v("\n      Grupo econômico\n    ")]
            )
          : _vm._e(),
      ]),
      _c(
        "section",
        {
          staticClass: "tabContent active pt-10",
          attrs: { id: "dadosCadastrais" },
        },
        [
          _vm.temGrupoEconomico && !_vm.tomadorEIgualGrupoEconomico
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        label: "Razão Social",
                        disabled: "true",
                        value: _vm.grupoEconomico.Name,
                        size: "large",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["##.###.###/####-##"],
                          expression: "['##.###.###/####-##']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: "CNPJ",
                        disabled: "true",
                        value: _vm.grupoEconomico.CpfCnpj,
                        size: "large",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.temGrupoEconomico && !_vm.tomadorEIgualGrupoEconomico
            ? _c("div", { staticClass: "vx-row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-1/3" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        label: "Data de Cadastro",
                        disabled: "true",
                        value: _vm._f("moment")(
                          _vm.grupoEconomico.CreateDate,
                          "DD/MM/YYYY"
                        ),
                        size: "large",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.temGrupoEconomico
            ? _c("div", { staticClass: "vx-row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "vs-alert",
                      { staticClass: "h-auto", attrs: { color: "warning" } },
                      [
                        _vm._v(
                          "\n          Grupo econômico não definido. Digite o documento do grupo econômico\n          e clique em salvar.\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.temGrupoEconomico &&
          !_vm.tomadorEIgualGrupoEconomico &&
          _vm.profileHelpers.checkPermission([
            _vm.profileHelpers.menuFuncionalitiesEnum
              .GERENCIAR_TOMADORES_SEGURADORA,
          ])
            ? _c("div", { staticClass: "vx-row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "vs-alert",
                      { staticClass: "h-auto", attrs: { color: "success" } },
                      [
                        _vm._v(
                          "\n          Grupo econômico definido. Para alterar o grupo econômico digite o\n          documento abaixo e clique em salvar.\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.temGrupoEconomico && _vm.tomadorEIgualGrupoEconomico
            ? _c("div", { staticClass: "vx-row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "vs-alert",
                      { staticClass: "h-auto", attrs: { color: "warning" } },
                      [_vm._v("\n          Risco Isolado.\n        ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.deveMostrarWarningMudancaLimiteGE
            ? _c("div", { staticClass: "vx-row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "vs-alert",
                      { staticClass: "h-auto", attrs: { color: "warning" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.mensagemWarningMudancaLimiteGE) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.temGrupoEconomico
            ? _c("div", { staticClass: "vx-row mt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression:
                            "['###.###.###-##', '##.###.###/####-##']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: "Documento do Grupo econômico",
                        size: "large",
                      },
                      model: {
                        value: _vm.cnpjGrupoEconomico,
                        callback: function ($$v) {
                          _vm.cnpjGrupoEconomico = $$v
                        },
                        expression: "cnpjGrupoEconomico",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col text-right w-full mt-5 action-buttons" },
              [
                _vm.profileHelpers.isAdvisor() && _vm.temGrupoEconomico
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "action-buttons-btn",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.abrirPopupAlterarGrupoEconomico()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v(
                            "\n            Alterar grupo econômico\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.temGrupoEconomico &&
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                ])
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "action-buttons-btn",
                        attrs: { color: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.atualizarGrupoEconomico(false)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v(
                            "\n            Atualizar grupo econômico\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  [
                    !_vm.permiteReset
                      ? _c(
                          "vx-tooltip",
                          {
                            staticClass: "enable-ccg",
                            attrs: {
                              text: "Desabilitado pois tomador já possui apólices emitidas",
                              position: "right",
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("info"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.temGrupoEconomico &&
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .RESETAR_GRUPO_ECONOMICO,
                    ])
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "ml-2 action-buttons-btn",
                            attrs: {
                              color: "primary",
                              disabled: !_vm.permiteReset,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.atualizarGrupoEconomico(true)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "font-semibold pr-2 pl-2" },
                              [
                                _vm._v(
                                  "\n              Resetar grupo econômico\n            "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "section",
        { staticClass: "tabContent pt-10", attrs: { id: "grupoEconomico" } },
        [
          _c(
            "vs-table",
            {
              staticClass: "mt-10 table-available",
              attrs: {
                noDataText: "Grupo econômico não cadastrado",
                data: _vm.grupoEconomicoList.PolicyHolders || [],
              },
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v("\n          Razão Social\n        ")]),
                  _c("vs-th", [_vm._v("\n          CNPJ\n        ")]),
                  _c("vs-th", [
                    _vm._v("\n          Limite Disponível\n        "),
                  ]),
                  _c("vs-th", [
                    _vm._v("\n          Limite Aprovado\n        "),
                  ]),
                  _c("vs-th", [
                    _vm._v("\n          Limite Utilizado\n        "),
                  ]),
                ],
                1
              ),
              [
                _vm._l(
                  _vm.grupoEconomicoList.PolicyHolders,
                  function (grupo, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr, attrs: { data: grupo } },
                      [
                        _c("vs-td", [_vm._v(_vm._s(grupo.Nome))]),
                        _c("vs-td", [
                          _vm._v(_vm._s(_vm._f("maskCpfCnpj")(grupo.CNPJ))),
                        ]),
                        _c(
                          "vs-td",
                          [
                            _c("currency-input", {
                              staticClass: "input-currency",
                              attrs: { disabled: "true" },
                              model: {
                                value: grupo.AvailableLimit,
                                callback: function ($$v) {
                                  _vm.$set(grupo, "AvailableLimit", $$v)
                                },
                                expression: "grupo.AvailableLimit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "vs-td",
                          [
                            _c("currency-input", {
                              staticClass: "input-currency",
                              attrs: { disabled: "true" },
                              model: {
                                value: grupo.ApprovedLimit,
                                callback: function ($$v) {
                                  _vm.$set(grupo, "ApprovedLimit", $$v)
                                },
                                expression: "grupo.ApprovedLimit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "vs-td",
                          [
                            _c("currency-input", {
                              staticClass: "input-currency",
                              attrs: { disabled: "true" },
                              model: {
                                value: grupo.UsedLimit,
                                callback: function ($$v) {
                                  _vm.$set(grupo, "UsedLimit", $$v)
                                },
                                expression: "grupo.UsedLimit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                _c(
                  "vs-tr",
                  { attrs: { state: "warning" } },
                  [
                    _c("vs-td", [
                      _c("p", { staticClass: "font-bold" }, [
                        _vm._v("Limite Total"),
                      ]),
                    ]),
                    _c("vs-td"),
                    _c(
                      "vs-td",
                      [
                        _c("currency-input", {
                          staticClass: "input-currency font-bold",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.grupoEconomicoList.AvailableLimit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.grupoEconomicoList,
                                "AvailableLimit",
                                $$v
                              )
                            },
                            expression: "grupoEconomicoList.AvailableLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-td",
                      [
                        _c("currency-input", {
                          staticClass: "input-currency font-bold",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.grupoEconomicoList.GrantedLimit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.grupoEconomicoList,
                                "GrantedLimit",
                                $$v
                              )
                            },
                            expression: "grupoEconomicoList.GrantedLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-td",
                      [
                        _c("currency-input", {
                          staticClass: "input-currency font-bold",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.grupoEconomicoList.UsedLimit,
                            callback: function ($$v) {
                              _vm.$set(_vm.grupoEconomicoList, "UsedLimit", $$v)
                            },
                            expression: "grupoEconomicoList.UsedLimit",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "change-economic-group-popup",
          attrs: { width: "28%", visible: _vm.popupAlterarGrupoEconomico },
          on: {
            "update:visible": function ($event) {
              _vm.popupAlterarGrupoEconomico = $event
            },
          },
        },
        [
          _c("div", { staticClass: "change-economic-group-popup-content" }, [
            _c(
              "div",
              { staticClass: "change-economic-group-popup-content-header" },
              [
                _c("i", {
                  staticClass: "onpoint-user user-card-icon",
                  attrs: { translate: "no" },
                }),
                _c("h3", [
                  _vm._v("\n            Alterar grupo econômico\n          "),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "change-economic-group-popup-content-body" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "change-economic-group-popup-content-body-input",
                  },
                  [
                    _c("label", { attrs: { for: "cpf-cnpj" } }, [
                      _vm._v("CPF ou CNPJ"),
                    ]),
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression:
                            "['###.###.###-##', '##.###.###/####-##']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        id: "cpf-cnpj",
                        size: "large",
                        label: "cpf-cnpj",
                      },
                      model: {
                        value: _vm.cpfCnpjNovoGrupoEconomico,
                        callback: function ($$v) {
                          _vm.cpfCnpjNovoGrupoEconomico = $$v
                        },
                        expression: "cpfCnpjNovoGrupoEconomico",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer change-economic-group-popup-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "change-economic-group-popup-footer-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.alterarGrupoEconomico },
                },
                [_vm._v("Salvar novo grupo econômico")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "change-economic-group-popup-footer-btn",
                  on: { click: _vm.fecharPopupAlterarGrupoEconomico },
                },
                [_vm._v("Cancelar")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }