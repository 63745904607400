<template>
  <div class="m-8 table-content">
    <adicionar-filial
      :uniqueIdTomador="this.uniqueIdTomador"
      @modal_close="onModalFilialClose"
      v-if="
        profileHelpers.checkPermission([
          profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES,
              profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA
        ])
      "
    />
    <template v-if="(dadosFiliais || []).length > 0">
      <vs-table
        class="table-list mt-8"
        :data="dadosFiliais"
        noDataText="Nenhuma filial encontrada."
      >
        <template slot="thead">
          <vs-th sort-key="cnpj">CNPJ</vs-th>
          <vs-th sort-key="name">Razão Social</vs-th>
          <vs-th sort-key="address">Endereço</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].CpfCnpj">
              <span class="block">
                <the-mask
                  class="the-mask text-center table-index-col"
                  :mask="['##.###.###/####-##']"
                  disabled="true"
                  :masked="true"
                  :value="data[indextr].CpfCnpj"
                />
              </span>
            </vs-td>

            <vs-td :data="data[indextr].Name">
              <p class="text-center">{{ data[indextr].Name }}</p>
            </vs-td>

            <vs-td :data="data[indextr].address">  
              <p class="text-center" v-if='data[indextr] && data[indextr].Location && data[indextr].Location.Address'>{{ data[indextr].Location.Address }}</p>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </template>
    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
        <i class="onpoint-file-text"></i>
        <p>Nenhuma filial cadastrada para esse tomador.</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TheMask } from "vue-the-mask";
import AdicionarFilial from "@/components/tomador/AdicionarFilial.vue";

import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "filiais",
  components: { TheMask, AdicionarFilial },
  props: {
    dadosFiliais: {
      type: Array,
      required: true
    },
    uniqueIdTomador: ""
  },
  data() {
    return {
      profileHelpers: PerfilHelpers
    };
  },
  methods: {
    ...mapActions("tomador-module", ["carregarTomador"]),
   async onModalFilialClose(event) {
     await this.$onpoint.loading( async () => {
        return await this.carregarTomador(this.$route.params.tomadorUniqueId).catch(
          ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          }
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--tbody {
    background-color: white !important;
  }

  ::v-deep .vs-table--thead {
    tr {
      background: transparent;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;

    .table-index-col {
      font-family: inherit !important;
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0px 8px 8px 0px;
  }
}
.onpoint-file-text::before{  
  font-size: 56px;
}
</style>
