var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-4" },
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _vm.listaApolices.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    staticClass: "table-list",
                    attrs: {
                      data: _vm.listaApolices,
                      hoverFlat: true,
                      noDataText: "Nenhuma apólice encontrada",
                      maxHeight: "72vh",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (item, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: item } },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data: data[indextr].PolicyNumber,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            data[indextr].PolicyNumber || "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.EndorsementCount },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.EndorsementCount > 0
                                                ? "Sim"
                                                : "Não"
                                            ) + "\n                "
                                          ),
                                          item.EndorsementCount > 0
                                            ? _c("vs-button", {
                                                staticClass:
                                                  "visualizar-endosso",
                                                attrs: {
                                                  radius: "",
                                                  color: "primary",
                                                  type: "flat",
                                                  icon: "remove_red_eye",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showEndorsement(
                                                      item.PolicyUniqueId
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data: data[indextr].InsuredName,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(data[indextr].InsuredName) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.PolicyStatusId },
                                    },
                                    [
                                      item.PolicyStatusId > 0
                                        ? _c(
                                            "vs-chip",
                                            {
                                              attrs: {
                                                color: _vm._f("setColorStatus")(
                                                  _vm.getStatus(
                                                    item.PolicyStatusId
                                                  )
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getStatus(
                                                      item.PolicyStatusId
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : [_vm._v("-")],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.IssueDate },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.IssueDate
                                              ? _vm.$utils.dateToDDMMYYYY(
                                                  item.IssueDate
                                                )
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.EndDate },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.EndDate
                                              ? _vm.$utils.dateToDDMMYYYY(
                                                  item.EndDate
                                                )
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "vs-progress",
                                        {
                                          attrs: {
                                            percent: _vm.getPercent(
                                              item.StartDate,
                                              item.EndDate,
                                              item.PolicyStatusId
                                            ),
                                            height: 8,
                                            color: _vm.getPercentColor(
                                              _vm.getPercent(
                                                item.StartDate,
                                                item.EndDate,
                                                item.PolicyStatusId
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("primary")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "btn-action-apolice",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticClass:
                                                "btn-action-apolice-options",
                                            },
                                            [
                                              _c("vs-dropdown-item", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-base px-6 py-2",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadApoliceClick(
                                                          data[indextr]
                                                            .OriginalProposalUniqueId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Visualizar apólice"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .GERENCIAR_TOMADORES_SEGURADORA,
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .VISUALIZAR_TOMADORES_SEGURADORA,
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .GERENCIAR_TOMADORES,
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .VISUALIZAR_TOMADORES,
                                                ]
                                              ) && data[indextr].BilletCount > 0
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.parcelasModal = true
                                                          _vm.$refs.parcelasModalComponent.carregarParcelas(
                                                            data[indextr]
                                                              .OriginalProposalUniqueId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Visualizar boleto(s)"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .DOWNLOAD_DO_LAUDO_DE_EMISSAO,
                                                ]
                                              ) &&
                                              data[indextr].PolicyStatusId == 2
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadLaudoEmissaoClick(
                                                            data[indextr]
                                                              .OriginalProposalUniqueId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Visualizar laudo de emissão"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .CANCELAR_APOLICE_MANUALMENTE,
                                                ]
                                              ) &&
                                              data[indextr].PolicyStatusId == 2
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.apoliceSelecionadaCancelamento =
                                                            data[
                                                              indextr
                                                            ].PolicyNumber
                                                          _vm.abrirPopupCancelamentoApolice()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Cancelar apólice manualmente"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      2305444485
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Número")]),
                        _c("vs-th", { staticClass: "text-center" }, [
                          _vm._v("Contém endosso?"),
                        ]),
                        _c("vs-th", { attrs: { "sort-key": "InsuredName" } }, [
                          _vm._v("Segurado"),
                        ]),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "PolicyStatusId" },
                          },
                          [_vm._v("Status")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "IssueDate" },
                          },
                          [_vm._v("Emissão")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "EndDate" },
                          },
                          [_vm._v("Vencimento")]
                        ),
                        _c("vs-th", { staticClass: "text-center" }, [
                          _vm._v("Vigência"),
                        ]),
                        _c("vs-th"),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            : [_vm._m(0)],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "policy-cancellation-popup",
          attrs: { width: "24%", visible: _vm.popupCancelamentoApolice },
          on: {
            "update:visible": function ($event) {
              _vm.popupCancelamentoApolice = $event
            },
          },
        },
        [
          _c("div", { staticClass: "policy-cancellation-popup-content" }, [
            _c(
              "div",
              { staticClass: "policy-cancellation-popup-content-header" },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                    alt: "Ícone de cancelamento",
                    width: "24",
                    height: "24",
                  },
                }),
                _c("h3", [_vm._v("\n          Cancelar apólice\n        ")]),
              ]
            ),
            _c(
              "div",
              { staticClass: "policy-cancellation-popup-content-body" },
              [
                _c(
                  "el-alert",
                  {
                    staticClass: "policy-cancellation-popup-content-body-alert",
                    attrs: { type: "warning", center: "", closable: false },
                  },
                  [
                    _vm._v(
                      "\n          O cancelamento da apólice é uma ação permanente, não podendo ser revertida após sua confirmação!\n        "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "policy-cancellation-popup-content-body-action",
                  },
                  [
                    _vm.apoliceSelecionadaCancelamento
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "policy-cancellation-popup-content-body-policy",
                          },
                          [
                            _vm._v(
                              "Número da apólice: " +
                                _vm._s(_vm.apoliceSelecionadaCancelamento)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        staticClass:
                          "policy-cancellation-popup-content-body-advice",
                      },
                      [
                        _vm._v(
                          "\n            Para efetuar o cancelamento, digite o número da apólice no campo abaixo:\n          "
                        ),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.chaveCancelamentoApolice,
                          expression: "chaveCancelamentoApolice",
                        },
                      ],
                      staticClass:
                        "policy-cancellation-popup-content-body-input",
                      domProps: { value: _vm.chaveCancelamentoApolice },
                      on: {
                        keyup: _vm.verificarChaveCancelamentoApolice,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.chaveCancelamentoApolice = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer policy-cancellation-popup-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "p",
                { staticClass: "policy-cancellation-popup-footer-question" },
                [_vm._v("Deseja continuar?")]
              ),
              _c(
                "div",
                { staticClass: "policy-cancellation-popup-footer-btns" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "policy-cancellation-popup-footer-btn",
                      class: {
                        desabilitado: _vm.desabilitarCancelamentoApolice,
                      },
                      attrs: {
                        type: "primary",
                        disabled: _vm.desabilitarCancelamentoApolice,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.internalCancelPolicy()
                        },
                      },
                    },
                    [_vm._v("Sim, cancelar apólice")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "policy-cancellation-popup-footer-btn",
                      on: { click: _vm.fecharPopupCancelamentoApolice },
                    },
                    [_vm._v("Não")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("listar-parcelas-popup", {
        ref: "parcelasModalComponent",
        attrs: { parcelasModal: _vm.parcelasModal, autoLoad: false },
        on: {
          fecharParcelasModal: function ($event) {
            _vm.parcelasModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32",
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", [_vm._v("Nenhuma apólice encontrada para este tomador")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }