<template>
  <div class="w-full">
    <div class="px-6">
      <h4>
        <b style="color:#626262 !important">Carta de nomeação</b>
      </h4>
      <p class="pt-4">
        Abaixo você pode anexar uma carta de nomeação e enviar para avaliação
        clicando no botão "Enviar carta de nomeação".
      </p>
    </div>

    <az-upload
      :singleUpload="true"
      :controller="uploadCartaNomeacaoController"
    />

    <vs-button
      @click.native="enviarCartaNomeacao()"
      class="ml-8"
      size="small"
      color="success"
    >
      <span class="font-semibold pr-2 pl-2">
        Enviar Carta de nomeação
      </span>
    </vs-button>
  </div>
</template>
<script>
import Upload from "@/components/az-upload/AzUpload.vue";

import { mapActions } from "vuex";

export default {
  name: "carta-nomeacao",
  components: {
    Upload
  },
  props: {
    tomador: Object,
    nomeationRequestCallback: Function
  },
  data() {
    return {
      uploadCartaNomeacaoController: {}
    };
  },
  methods: {
    ...mapActions("tomador-module", ["solicitarNomeacaoTomador"]),
    async enviarCartaNomeacao() {
      const tomadorSelecionado = this.tomador;
      const files = this.uploadCartaNomeacaoController.files();
      if (!tomadorSelecionado || tomadorSelecionado.Id == 0) {
        this.$onpoint.errorModal("Informe o tomador no componente.");
        return;
      }

      if (!files || files.length == 0) {
        this.$onpoint.errorModal(
          "Selecioner uma carta para enviar para análise."
        );
        return;
      }
      await this.$onpoint.loadingModal(
        "Estamos enviando sua carta de nomeação para análise.",
        async () => {
          return await this.uploadCartaNomeacaoController
            .upload()
            .then(async uploadedFiles => {
              const request = {
                PolicyHolderId: tomadorSelecionado.Id,
                NomeationTypeId: 3, //Carta de nomeação
                Files: uploadedFiles
              };
              return await this.solicitarNomeacaoTomador(request).then(() => {
                this.$onpoint.successModal(
                  "Carta de nomeação enviada com sucesso. Aguarde a análise da seguradora."
                );
                this.nomeationRequestCallback({
                  ...tomadorSelecionado,
                  HasPendingNomeationProcess: true,
                  LastPolicyHolderNomeationRequest: {
                    RequestDate: this.$moment()
                  }
                });
              });
            })
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        }
      );
    }
  }
};
</script>
