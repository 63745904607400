<template>
  <div class="px-4 py-4">
    <div class="tab">
      <button
        class="tabLinks cursor-pointer font-medium w-auto active"
        @click="e => openTab(e, 'dadosCadastrais')"
      >
        Dados cadastrais
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'grupoEconomico')"
        v-if="temGrupoEconomico"
      >
        Grupo econômico
      </button>
    </div>
    <section id="dadosCadastrais" class="tabContent active pt-10">
      <div
        class="vx-row"
        v-if="temGrupoEconomico && !tomadorEIgualGrupoEconomico"
      >
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            label="Razão Social"
            disabled="true"
            :value="grupoEconomico.Name"
            size="large"
          />
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            label="CNPJ"
            disabled="true"
            :value="grupoEconomico.CpfCnpj"
            size="large"
            v-mask="['##.###.###/####-##']"
          />
        </div>
      </div>
      <div
        class="vx-row pt-5"
        v-if="temGrupoEconomico && !tomadorEIgualGrupoEconomico"
      >
        <div class="vx-col w-1/3">
          <vs-input
            class="w-full"
            label="Data de Cadastro"
            disabled="true"
            :value="grupoEconomico.CreateDate | moment('DD/MM/YYYY')"
            size="large"
          />
        </div>
      </div>
      <div class="vx-row pt-5" v-if="!temGrupoEconomico">
        <div class="vx-col w-full">
          <vs-alert color="warning" class="h-auto">
            Grupo econômico não definido. Digite o documento do grupo econômico
            e clique em salvar.
          </vs-alert>
        </div>
      </div>
      <div
        class="vx-row pt-5"
        v-if="temGrupoEconomico && !tomadorEIgualGrupoEconomico && profileHelpers.checkPermission([
                    profileHelpers.menuFuncionalitiesEnum
                      .GERENCIAR_TOMADORES_SEGURADORA
                  ])"
      >
        <div class="vx-col w-full">
          <vs-alert color="success" class="h-auto">
            Grupo econômico definido. Para alterar o grupo econômico digite o
            documento abaixo e clique em salvar.
          </vs-alert>
        </div>
      </div>
      <div
        class="vx-row pt-5"
        v-if="temGrupoEconomico && tomadorEIgualGrupoEconomico"
      >
        <div class="vx-col w-full">
          <vs-alert color="warning" class="h-auto">
            Risco Isolado.
          </vs-alert>
        </div>
      </div>
      <div
        class="vx-row pt-5"
        v-if="deveMostrarWarningMudancaLimiteGE"
      >
        <div class="vx-col w-full">
          <vs-alert color="warning" class="h-auto">
            {{ mensagemWarningMudancaLimiteGE }}
          </vs-alert>
        </div>
      </div>
      <div class="vx-row mt-5" v-if="!temGrupoEconomico">
        <div class="vx-col w-full">
          <vs-input
            label="Documento do Grupo econômico"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="cnpjGrupoEconomico"
            size="large"
            class="w-full"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col text-right w-full mt-5 action-buttons">
          <vs-button
            v-if="profileHelpers.isAdvisor() && temGrupoEconomico"
            color="primary"
            class="action-buttons-btn"
            @click="abrirPopupAlterarGrupoEconomico()"
          >
            <span class="font-semibold pr-2 pl-2">
              Alterar grupo econômico
            </span>
          </vs-button>

          <vs-button
            @click.native="atualizarGrupoEconomico(false)"
            color="primary"
            class="action-buttons-btn"
            v-if="!temGrupoEconomico  &&
                  profileHelpers.checkPermission([
                    profileHelpers.menuFuncionalitiesEnum
                      .GERENCIAR_TOMADORES_SEGURADORA
                  ])"
          >
            <span class="font-semibold pr-2 pl-2">
              Atualizar grupo econômico
            </span>
          </vs-button>
          <div>
            <vx-tooltip
              v-if="!permiteReset"
              text="Desabilitado pois tomador já possui apólices emitidas"
              position="right"
              class="enable-ccg"
            >
              <i class="material-icons">info</i>
            </vx-tooltip>
            <vs-button
              @click.native="atualizarGrupoEconomico(true)"
              color="primary"
              v-if="
                temGrupoEconomico &&
                  profileHelpers.checkPermission([
                    profileHelpers.menuFuncionalitiesEnum
                      .RESETAR_GRUPO_ECONOMICO
                  ])
              "
              :disabled="!permiteReset"
              class="ml-2 action-buttons-btn"
            >
              <span class="font-semibold pr-2 pl-2">
                Resetar grupo econômico
              </span>
            </vs-button>
          </div>
        </div>
      </div>
    </section>
    <section id="grupoEconomico" class="tabContent pt-10">
      <vs-table
        class="mt-10 table-available"
        noDataText="Grupo econômico não cadastrado"
        :data="grupoEconomicoList.PolicyHolders || []"
      >
        <template slot="thead">
          <vs-th>
            Razão Social
          </vs-th>
          <vs-th>
            CNPJ
          </vs-th>
          <vs-th>
            Limite Disponível
          </vs-th>
          <vs-th>
            Limite Aprovado
          </vs-th>
          <vs-th>
            Limite Utilizado
          </vs-th>
        </template>
        <template>
          <vs-tr
            :data="grupo"
            :key="indextr"
            v-for="(grupo, indextr) in grupoEconomicoList.PolicyHolders"
          >
            <vs-td>{{ grupo.Nome }}</vs-td>
            <vs-td>{{ grupo.CNPJ | maskCpfCnpj }}</vs-td>
            <vs-td>
              <currency-input
                class="input-currency"
                v-model="grupo.AvailableLimit"
                disabled="true"
              />
            </vs-td>
            <vs-td>
              <currency-input
                class="input-currency"
                v-model="grupo.ApprovedLimit"
                disabled="true"
            /></vs-td>
            <vs-td>
              <currency-input
                class="input-currency"
                v-model="grupo.UsedLimit"
                disabled="true"
              />
            </vs-td>
          </vs-tr>
          <vs-tr state="warning">
            <vs-td>
              <p class="font-bold">Limite Total</p>
            </vs-td>
            <vs-td></vs-td>
            <vs-td>
              <currency-input
                class="input-currency font-bold"
                v-model="grupoEconomicoList.AvailableLimit"
                disabled="true"
              />
            </vs-td>
            <vs-td>
              <currency-input
                class="input-currency font-bold"
                v-model="grupoEconomicoList.GrantedLimit"
                disabled="true"
              />
            </vs-td>
            <vs-td>
              <currency-input
                class="input-currency font-bold"
                v-model="grupoEconomicoList.UsedLimit"
                disabled="true"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </section>

    <!-- Popup alterar grupo econômico -->
    <el-dialog
        width="28%"
        :visible.sync="popupAlterarGrupoEconomico"
        class="change-economic-group-popup"
      >
        <div class="change-economic-group-popup-content">
          <div class="change-economic-group-popup-content-header">
            <i translate="no" class="onpoint-user user-card-icon"/>
            <h3>
              Alterar grupo econômico
            </h3>
          </div>

          <div class="change-economic-group-popup-content-body">
            <div class="change-economic-group-popup-content-body-input">
              <label for="cpf-cnpj">CPF ou CNPJ</label>
              <el-input
                id="cpf-cnpj"
                size="large"
                class="w-full"
                label="cpf-cnpj"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                v-model="cpfCnpjNovoGrupoEconomico"
              ></el-input>
            </div>
          </div>
        </div>

        <div
          slot="footer"
          class="dialog-footer change-economic-group-popup-footer"
        >
          <el-button type="primary" class="change-economic-group-popup-footer-btn" @click="alterarGrupoEconomico"
            >Salvar novo grupo econômico</el-button
          >

          <el-button class="change-economic-group-popup-footer-btn" @click="fecharPopupAlterarGrupoEconomico"
            >Cancelar</el-button
          >
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
import instance from "@/axios";

import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "grupo-economico",
  props: {
    tomador: {
      type: Object,
      required: true
    }
  },
  directives: { mask },
  components: {
    TheMask
  },
  data() {
    return {
      // alguns campos não foram preenchidos -> precisa ver a api
      cnpjGrupoEconomico: "",
      grupoEconomicoList: [],
      availableLimit: null,
      grantedLimit: null,
      permiteReset: false,

      profileHelpers: PerfilHelpers,
      popupAlterarGrupoEconomico: false,
      cpfCnpjNovoGrupoEconomico: "",
      deveMostrarWarningMudancaLimiteGE: false,
      mensagemWarningMudancaLimiteGE: "",
    };
  },
  async mounted() {
    this.getGrupoEconomicoList(this.tomador.EconomicGroupId);
    this.validarSeHaEmissoes(this.tomador.UniqueId);

    await this.consultarLimiteGrupoEconomico(this.tomador.UniqueId)
  },
  computed: {
    temGrupoEconomico: {
      get() {
        return this.grupoEconomico != null && this.grupoEconomico.Id > 0;
      }
    },
    grupoEconomico() {
      const grupoEconomico = (this.tomador || {}).EconomicGroup;
      return grupoEconomico;
    },
    tomadorEIgualGrupoEconomico: {
      get() {
        return this.tomador.CpfCnpj == this.grupoEconomico.CpfCnpj;
      }
    }
  },
  methods: {
    ...mapActions("tomador-module", ["carregarTomador"]),

    async atualizarGrupoEconomico(limpar) {
      await this.$onpoint.loading(async () => {
        const data = limpar
          ? {
              ResetEconomicGroup: true,
              PolicyHolderUniqueId: this.tomador.UniqueId
            }
          : {
              PolicyHolderUniqueId: this.tomador.UniqueId,
              CpfCnpjEconomicGroup: this.cnpjGrupoEconomico
            };

        return await instance({
          method: "post",
          url: "/api/PolicyHolder/UpdatePolicyHolderEconomicGroup",
          data: data
        })
          .then(data => {
            this.tomador.EconomicGroup = data.data.Response;

          })
          .then(async () => {
            this.carregarPagina();
          })
          .then(() => {
            this.$onpoint.successModal(
              `Grupo econômico ${
                limpar ? "resetado" : "atualizado"
              } com sucesso!`
            );
          })
          .catch(ex => this.$onpoint.errorModal(ex));
      });
    },
    openTab(event, tabName) {
      [...document.getElementsByClassName("tabContent")].forEach(content => {
        content.classList.remove("active");
      });

      [...document.getElementsByClassName("tabLinks")].forEach(link => {
        link.classList.remove("active");
      });

      document.getElementById(tabName).classList.add("active");
      event.currentTarget.classList.add("active");
    },

    getGrupoEconomicoList(idGrupoEconomico) {
      idGrupoEconomico = idGrupoEconomico == undefined ? 0 : idGrupoEconomico;
      this.$onpoint.loading(() => {
        return instance
          .get(
            `/api/policyholder/GetPolicyHolderEconomicGroupInfo/${
              idGrupoEconomico ? idGrupoEconomico : 0
            }`
          )
          .then(resp => (this.grupoEconomicoList = resp.data.Response))
          .catch(error => this.$onpoint.errorModal(error[0]));
      });
    },

    async carregarPagina() {
      await this.$onpoint.loading(async () => {
        return await this.carregarTomador(this.$route.params.tomadorUniqueId)
          .then(resp =>
            this.getGrupoEconomicoList(this.tomador.EconomicGroupId)
          )
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    validarSeHaEmissoes(policyHolderUniqueId) {
      this.$onpoint.loading(() => {
        return instance({
          method: "get",
          url: `/api/Policy/ValidatePolicyHolderHasIssuedPolicies?PolicyHolderUniqueId=${policyHolderUniqueId}`
        })
          .then(response => response.data.Response)
          .then(response => {
            this.permiteReset = !response;
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    async consultarLimiteGrupoEconomico(policyHolderUniqueId){
      await this.$onpoint.loading(async () => {
        return await instance({
          method: "get",
          url: `/api/PolicyHolder/HasChangeEconomicGroup?PolicyHolderUniqueId=${policyHolderUniqueId}`
        })
          .then(response => response.data.Response)
          .then( response => {
            this.deveMostrarWarningMudancaLimiteGE = response.HasChange;
            this.mensagemWarningMudancaLimiteGE = response.Mensagem;
          })
          .catch(error => this.$onpoint.errorModal(error));
      });
    },

    async alterarGrupoEconomico(){
      let cpfCnpj = this.cpfCnpjNovoGrupoEconomico.replace(/[^\d]/g, "")
      const data = {
          ResetEconomicGroup: false,
          PolicyHolderUniqueId: this.tomador.UniqueId,
          CpfCnpjEconomicGroup: cpfCnpj,
          ChangeEconomicGroup: true
      };

      await this.$onpoint.loading(async () => {
        return await instance({
          method: "post",
          url: "/api/PolicyHolder/UpdatePolicyHolderEconomicGroup",
          data: data
        })
          .then(data => {
            this.tomador.EconomicGroup = data.data.Response;
          })
          .then(async () => {
            await this.carregarPagina();
          })
          .then(async() => {
            await this.consultarLimiteGrupoEconomico(this.tomador.UniqueId)
          })
          .then(() => {
            this.fecharPopupAlterarGrupoEconomico()
            this.$onpoint.successModal("Grupo econômico alterado com sucesso!");
          })
          .catch(ex => this.$onpoint.errorModal(ex));
      });
    },

    abrirPopupAlterarGrupoEconomico() {
      this.cpfCnpjNovoGrupoEconomico = ""
      this.popupAlterarGrupoEconomico = true;
    },

    fecharPopupAlterarGrupoEconomico() {
      this.cpfCnpjNovoGrupoEconomico = ""
      this.popupAlterarGrupoEconomico = false;
    }
  }
};
</script>

<style lang="scss">
#grupoEconomico {
  .input-currency {
    text-align: left;
  }
}
.tab {
  overflow: hidden;
  background-color: white;

  button {
    background-color: white;
    float: left;
    border: none;
    border-bottom: 2px solid #d9d9d9;
    outline: none;
    padding: 10px 14px;
    transition: 0.3s;
    font-size: inherit;
    color: #848484;
    border-radius: 0 !important;
    font-family: inherit;

    &.active {
      color: rgba(var(--vs-warning), 1);
      border-bottom: 2px solid rgba(var(--vs-warning), 1);
    }
  }
}

/* Style the tab content */
.tabContent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: 2px solid #d9d9d9;
  margin-top: -2px;

  &.active {
    display: block;
  }
}

.enable-ccg .material-icons {
  font-size: 0.85rem;
  color: rgba(var(--vs-warning), 1);
  font-size: 0.8em;
  width: 10px !important;
  float: right !important;
}

.action-buttons{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  &-btn{
    height: 39px;
  }
}

.change-economic-group-popup {
  & > div {
    border: none !important;
    border-radius: 8px !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &-content {
    &-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid #747474;

      & h3 {
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
      }
    }

    &-body {
      padding: 36px 0;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #747474;

      & label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #79747e;
      }

      &-input {
        margin-bottom: 36px;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;

    &-btn {
      font-weight: 600;
    }
  }
}
.user-card-icon::before{
  font-size: 50px;
}
</style>
