<template>
  <div class="pt-5 limites_taxas">
    <vs-row>
      <vs-col class="w-1/2">
        <p>Capacidade de Pagamento</p>
        <currency-input
          v-model="tomadorDetail.PaymentCapacity"
          class="w-full vs-inputx vs-input--input large hasValue"
        />
      </vs-col>
      <vs-col class="w-1/2">
        <p>Faturamento Real</p>
        <currency-input
          v-model="tomadorDetail.RealRevenue"
          class="w-full vs-inputx vs-input--input large hasValue"
        />
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="w-1/2">
        <p>Patrimonio Liquido</p>
        <currency-input
          v-model="tomadorDetail.NetWorth"
          class="w-full vs-inputx vs-input--input large hasValue"
        />
      </vs-col>
      <vs-col class="w-1/2">
        <p>Porte</p>
        <vs-input v-model="tomadorDetail.CompanySize" size="large" />
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="w-1/2">
        <p>Limite Revisado</p>
        <currency-input
          v-model="tomadorDetail.RevisedLimit"
          class="w-full vs-inputx vs-input--input large hasValue"
        />
      </vs-col>
      <vs-col class="w-1/2">
        <p>Data da Revisão</p>
        <el-date-picker
          v-model="tomadorDetail.RevisionDate"
          type="date"
          format="dd/MM/yyyy"
          :default-value="today"          
        >
        </el-date-picker>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="w-full">
        <vs-textarea
          label="observações"
          v-model="tomadorDetail.Observations"
          height="15rem"
        />
      </vs-col>

      <vs-button @click="analiseCredito()" class="button-primary">
        Salvar
      </vs-button>
    </vs-row>
  </div>
</template>
<script>
import axiosInstance from "@/axios";
import instance from "@/axios";
import { mask, TheMask } from "vue-the-mask";

export default {
  name: "analise-credito",
  directives: { mask },
  components: {
    TheMask
  },
  data() {
    return {
      tomadorDetail: {
        PaymentCapacity: 0,
        RealRevenue: 0,
        NetWorth: 0
      },
      today: "01/01/2023"
    };
  },
  created() {
    this.carregarTomador();
    this.today = new Date();
  },
  methods: {
    carregarTomador() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/PolicyHolder/GetPolicyHolder?UniqueId=${this.$route.params.tomadorUniqueId}`
          )
          .then(response => {
            this.tomadorDetail = response.data.Response;
          });
      });
    },
    analiseCredito() {
      this.$onpoint.loading(() => {
        this.showModalDate = false;
        return instance({
          method: "post",
          url: `/api/PolicyHolder/UpdatePolicyHolderCreditAnalysis`,
          data: {
            PolicyHolderUniqueId: this.tomadorDetail.UniqueId,
            PaymentCapacity: this.tomadorDetail.PaymentCapacity,
            RealRevenue: this.tomadorDetail.RealRevenue,
            CompanySize: this.tomadorDetail.CompanySize,
            NetWorth: this.tomadorDetail.NetWorth,
            Observations: this.tomadorDetail.Observations,
            RevisedLimit: this.tomadorDetail.RevisedLimit,
            RevisionDate: this.tomadorDetail.RevisionDate
          }
        })
          .then(response => response.data.Response)
          .then(response => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Tomador Atualizado  com sucesso!`,
              color: "success"
            });
            // this.$forceUpdate();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    }
  }
};
</script>

<style lang="scss" coped>
textarea {
  height: 90%;
}
</style>
