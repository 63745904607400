<template>
  <div>
    <div>
      <el-button
        type="primary"
        round
        class="btn-view-changes"
        @click="lidarComHistoricoAlteracoes()"
      >
        <i class="onpoint-clock-counter-clockwise icon-font pr-3"></i>
        Histórico de alterações
      </el-button>
    </div>

    <!-- Pop up Histórico de alterações -->
    <el-dialog
      :visible.sync="popupHistoricoAlteracoes"
      close-on-click-modal
      modal-append-to-body
      center
      class="history-change"
    >
      <div class="pop-up-content">
        <div class="pop-up-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/history-icon-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          <h3>Histórico de alterações</h3>
        </div>

        <div class="pop-up-content-comparation">
          <div class="pop-up-content-comparation-header">
            <div class="pop-up-content-comparation-header-data">
              Dados
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-before">
              Antes
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-after">
              Depois
            </div>
          </div>
          <ul class="pop-up-content-comparation-items">
            <li
              class="pop-up-content-comparation-item"
              v-for="(alteracao, index) in alteracoes"
              :key="index"
            >
              <div class="pop-up-content-comparation-item-data">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Data:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ formatarData(alteracao.Data) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Usuário:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.NomeUsuario }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Ação:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Acao }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider"></div>

              <div class="pop-up-content-comparation-item-before">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    {{
                      alteracao.Antes.TipoParteId ? "Status da parte:" : "Status do contrato:"
                    }}
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Antes.StatusId
                        ? tipoStatusId(alteracao.Antes.StatusId)
                        : "-"
                    }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Tipo da parte:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Antes.TipoParteId
                        ? tipoParte(alteracao.Antes.TipoParteId)
                        : "-"
                    }}
                  </p>
                </div>

                <div
                  class="pop-up-content-comparation-item-row"
                  v-if="
                    alteracao.Antes.TipoParteId == 2 ||
                      alteracao.Depois.TipoParteId == 2
                  "
                >
                  <p class="pop-up-content-comparation-item-title">
                    Razão social:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Antes.NomeEmpresa
                        ? alteracao.Antes.NomeEmpresa
                        : "-"
                    }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">Nome:</p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Antes.Nome ? alteracao.Antes.Nome : "-" }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">E-mail:</p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Antes.Email ? alteracao.Antes.Email : "-" }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider-arrow">
                <i class="el-icon-right"></i>
              </div>

              <div class="pop-up-content-comparation-item-after">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    {{
                      alteracao.Depois.TipoParteId ? "Status da parte:" : "Status do contrato:"
                    }}
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Depois.StatusId
                        ? tipoStatusId(alteracao.Depois.StatusId)
                        : "-"
                    }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Tipo da parte:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Depois.TipoParteId
                        ? tipoParte(alteracao.Depois.TipoParteId)
                        : "-"
                    }}
                  </p>
                </div>

                <div
                  class="pop-up-content-comparation-item-row"
                  v-if="
                    alteracao.Antes.TipoParteId == 2 ||
                      alteracao.Depois.TipoParteId == 2
                  "
                >
                  <p class="pop-up-content-comparation-item-title">
                    Razão social:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{
                      alteracao.Depois.NomeEmpresa
                        ? alteracao.Depois.NomeEmpresa
                        : "-"
                    }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">Nome:</p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Depois.Nome ? alteracao.Depois.Nome : "-" }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">E-mail:</p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Depois.Email ? alteracao.Depois.Email : "-" }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="dialog-footer pop-up-footer">
        <el-button type="primary" @click="popupHistoricoAlteracoes = false"
          >Fechar</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import ccgController from "@/components/CCG/providers/ccg-provider.js";

export default {
  name: "visualizar-alteracoes-ccg",
  props: {
    contrato: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alteracoes: {},
      popupHistoricoAlteracoes: false
    };
  },
  methods: {
    async lidarComHistoricoAlteracoes() {
      let contratoId = this.contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .obterHistoricoAlteracoes(contratoId)
          .then(data => {
            let mudancas = data;
            for (let i = 0; i < mudancas.length; i++) {
              this.converterStringsParaObjetos(mudancas[i]);
            }

            this.alteracoes = this.inverterOrdemDoArray(mudancas);
          })
          .then(() => {
            this.exibirHistoricoAlteracoes();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    exibirHistoricoAlteracoes() {
      this.popupHistoricoAlteracoes = true;
    },

    formatarData(date) {
      if (date instanceof Date) {
        date = date.setHours(0, 0, 0, 0);
      }
      return moment(date).format("DD/MM/YYYY");
    },

    tipoParte(tipoParteId) {
      switch (tipoParteId) {
        case "1":
          return "Representante Legal";
        case "2":
          return "Fiador Pessoa Jurídica";
        case "3":
          return "Fiador Pessoa Física";
        case "4":
          return "Testemunha";
        case "5":
          return "Cônjuge";
        case "6":
          return "Seguradora";
        default:
          return "";
      }
    },

    tipoStatusId(statusId) {
      switch (statusId) {
        case "1":
          return "Criado";
        case "2":
          return "Aguardando Assinatura";
        case "3":
          return "Assinado";
        default:
          return "";
      }
    },

    converterStringsParaObjetos(data) {
      if (data.Antes !== "") {
        data.Antes = JSON.parse(data.Antes);
      }
      if (data.Depois !== "") {
        data.Depois = JSON.parse(data.Depois);
      }
    },

    inverterOrdemDoArray(data) {
      const arrayInvertido = [];
      for (let i = data.length - 1; i >= 0; i--) {
        arrayInvertido.push(data[i]);
      }
      return arrayInvertido;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-view-changes {
  height: fit-content;
  padding: 10px 24px 10px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  &:hover {
    background: rgba(var(--vs-primary), 0.8) !important;
  }
}

.icon-font::before{
  position: relative;
  top: 2px;
}

.el-button.is-disabled {
  background: rgba(var(--vs-primary), 0.8) !important;
  pointer-events: none;
}

.pop-up-content {
  &-header {
    width: 100%;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
    padding-bottom: 16px;
    border-bottom: 1px solid #747474;

    & i {
      width: 20px;
      height: 20px;

      &::before {
        font-size: 20px;
      }
    }

    & h3 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  &-comparation {
    padding: 36px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-header {
      width: 100%;
      display: flex;
      padding: 0 16px 0 0;

      &-before,
      &-after,
      &-divider,
      &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-divider {
        margin: 0 18px;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
        height: 50px;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: rgba(13, 27, 51, 1);
      }

      &-data {
        background-color: rgba(236, 236, 236, 1);
      }

      &-before {
        background-color: rgba(236, 236, 236, 1);
      }

      &-after {
        background-color: rgba(174, 255, 192, 1);
      }
    }

    &-items {
      height: 450px;
      overflow: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 36px 0;
      border-bottom: 1px solid rgba(116, 116, 116, 1);

      &-before,
      &-after,
      &-divider,
      &-divider-arrow,
      &-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
      }

      &-divider {
        margin: 0 18px;
      }

      &-divider-arrow {
        & i {
          width: 36px;
          height: 36px;

          &::before {
            font-size: 36px;
            color: rgba(236, 236, 236, 1) !important;
          }
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-title {
        width: 50%;
        text-align: end;
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        word-break: break-word;
      }

      &-info {
        width: 50%;
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(13, 27, 51, 1);
        word-break: break-word;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}

.pop-up-footer {
  padding: 36px 36px;
  border-top: 1px solid rgba(116, 116, 116, 1);
}
</style>
