var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-8 table-content" },
    [
      _vm.profileHelpers.checkPermission([
        _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES,
        _vm.profileHelpers.menuFuncionalitiesEnum
          .GERENCIAR_TOMADORES_SEGURADORA,
      ])
        ? _c("adicionar-filial", {
            attrs: { uniqueIdTomador: this.uniqueIdTomador },
            on: { modal_close: _vm.onModalFilialClose },
          })
        : _vm._e(),
      (_vm.dadosFiliais || []).length > 0
        ? [
            _c(
              "vs-table",
              {
                staticClass: "table-list mt-8",
                attrs: {
                  data: _vm.dadosFiliais,
                  noDataText: "Nenhuma filial encontrada.",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].CpfCnpj } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "block" },
                                    [
                                      _c("the-mask", {
                                        staticClass:
                                          "the-mask text-center table-index-col",
                                        attrs: {
                                          mask: ["##.###.###/####-##"],
                                          disabled: "true",
                                          masked: true,
                                          value: data[indextr].CpfCnpj,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].Name } },
                                [
                                  _c("p", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(data[indextr].Name)),
                                  ]),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].address } },
                                [
                                  data[indextr] &&
                                  data[indextr].Location &&
                                  data[indextr].Location.Address
                                    ? _c("p", { staticClass: "text-center" }, [
                                        _vm._v(
                                          _vm._s(data[indextr].Location.Address)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  2747674773
                ),
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", { attrs: { "sort-key": "cnpj" } }, [
                      _vm._v("CNPJ"),
                    ]),
                    _c("vs-th", { attrs: { "sort-key": "name" } }, [
                      _vm._v("Razão Social"),
                    ]),
                    _c("vs-th", { attrs: { "sort-key": "address" } }, [
                      _vm._v("Endereço"),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        : [_vm._m(0)],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32",
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", [_vm._v("Nenhuma filial cadastrada para esse tomador.")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }