var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "btn-list-actions",
      class: { hide: _vm.ocultarAcoes },
      on: { mouseover: _vm.deveExibirMenu, mouseleave: _vm.deveOcultarMenu },
    },
    [
      _c("button", { staticClass: "list-actions" }, [
        !_vm.exibirMenu
          ? _c("i", { staticClass: "el-icon-s-unfold" })
          : _c("i", { staticClass: "el-icon-s-fold" }),
        _vm._v("\n    Ações\n    "),
        _c("i", { staticClass: "el-icon-caret-bottom" }),
      ]),
      _vm.exibirMenu
        ? _c("ul", { staticClass: "menu-open" }, [
            _vm.deveExibirEditar(_vm.parte.TipoParteName) &&
            _vm.parte.TipoParteStatusId == 1
              ? _c(
                  "div",
                  {
                    staticClass: "menu-open-item",
                    on: { click: _vm.editarDados },
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm.exibirCadastroConjuge &&
            !_vm.parte.Conjuge &&
            _vm.parte.TipoParteStatusId == 1 &&
            _vm.conjugeHabilitado
              ? _c(
                  "div",
                  {
                    staticClass: "menu-open-item",
                    on: { click: _vm.cadastrarConjuge },
                  },
                  [_vm._m(1)]
                )
              : _vm._e(),
            _vm.podeReenviarEmailIndividual && _vm.parte.TipoParteStatusId == 2
              ? _c(
                  "div",
                  {
                    staticClass: "menu-open-item",
                    on: { click: _vm.reenviarEmail },
                  },
                  [_vm._m(2)]
                )
              : _vm._e(),
            _vm.podeEnviarLinkAssinaturaIndividual &&
            _vm.parte.LinkAssinatura &&
            _vm.parte.TipoParteStatusId != 3
              ? _c(
                  "div",
                  {
                    staticClass: "menu-open-item",
                    on: {
                      click: function ($event) {
                        return _vm.copiarLinkAssinatura()
                      },
                    },
                  },
                  [_vm._m(3)]
                )
              : _vm._e(),
            _vm.deveExibirExluir(_vm.parte.TipoParteName) &&
            _vm.parte.TipoParteStatusId == 1
              ? _c(
                  "div",
                  {
                    staticClass: "menu-open-item",
                    on: { click: _vm.excluirRepresentante },
                  },
                  [
                    _c("li", { staticClass: "menu-open-item-title" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                          alt: "Ícone de excluir representante",
                          width: "24",
                          height: "24",
                        },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.parte.TipoParteName === "Representante Legal"
                              ? "Excluir representante"
                              : _vm.parte.TipoParteName === "Conjuge"
                              ? "Excluir cônjuge"
                              : _vm.parte.TipoParteName === "Testemunha"
                              ? "Excluir testemunha"
                              : "Excluir fiador"
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "menu-open-item-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/ccg-icons/edit-pencil-ccg.svg"),
          alt: "Ícone de edição de dados",
          width: "24",
          height: "24",
        },
      }),
      _vm._v("\n        Edição de dados\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "menu-open-item-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/ccg-icons/spouse-icon-ccg.svg"),
          alt: "Ícone de edição de dados",
          width: "24",
          height: "24",
        },
      }),
      _vm._v("\n        Cadastrar cônjuge\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "menu-open-item-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/ccg-icons/forward-to-inbox-ccg.svg"),
          alt: "Ícone de reenviar de email",
          width: "24",
          height: "24",
        },
      }),
      _vm._v("\n        Reenviar e-mail\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "menu-open-item-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/ccg-icons/link-icon-ccg.svg"),
          alt: "Ícone de link individual de assinatura",
          width: "24",
          height: "24",
        },
      }),
      _vm._v("\n        Link individual de assinatura\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }