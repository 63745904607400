var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _vm._m(0),
      _c("az-upload", {
        attrs: {
          singleUpload: true,
          controller: _vm.uploadCartaNomeacaoController,
        },
      }),
      _c(
        "vs-button",
        {
          staticClass: "ml-8",
          attrs: { size: "small", color: "success" },
          nativeOn: {
            click: function ($event) {
              return _vm.enviarCartaNomeacao()
            },
          },
        },
        [
          _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
            _vm._v("\n      Enviar Carta de nomeação\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "px-6" }, [
      _c("h4", [
        _c("b", { staticStyle: { color: "#626262 !important" } }, [
          _vm._v("Carta de nomeação"),
        ]),
      ]),
      _c("p", { staticClass: "pt-4" }, [
        _vm._v(
          '\n      Abaixo você pode anexar uma carta de nomeação e enviar para avaliação\n      clicando no botão "Enviar carta de nomeação".\n    '
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }