<template>
  <div>
    <vs-table :data="consorcios" noDataText="Nenhum consórcio encontrado." v-if="consorcios.length > 0">
      <template slot="thead">
        <vs-th sort-key="cnpj">CNPJ</vs-th>
        <vs-th sort-key="razaoSocial">Razão Social</vs-th>
        <vs-th sort-key="participacao">% Participação</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].cnpj">
            {{data[indextr].cnpj}}
          </vs-td>
          <vs-td :data="data[indextr].razaoSocial">
            {{data[indextr].razaoSocial}}
          </vs-td>
          <vs-td :data="data[indextr].participacao">
            {{data[indextr].participacao}}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center my-32">
        <p><span class="material-icons text-6xl">description</span></p>
        <p>Nenhum consórcio cadastrado para esse tomador.</p>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "consorcio",
  data () {
    return {
      consorcios: []
    };
  }
};
</script>