<template>
  <div class="cadastro-tomador new-tabs">
    <div class="breadcrumb mb-6">
      <breadcrumb
        title="Tomador"
        :actualPage="(tomador || {}).Name || ''"
        previousPage="tomadores"
        previousPageTitle="Lista de tomadores"
      />
    </div>

    <div class="perfil-tomador">
      <vs-tabs color="warning" v-if="!!tomador">
        <vs-tab label="Cadastro Completo">
          <dados-gerais
            :dadoCNAE="tomador.CNAEs[0]"
            :endereco="tomador.Location"
            :telefone="tomador.Phones[0]"
            @updateSupplementaryData="updateSupplementaryData"
            :exibirParcelamentoAnual="exibirHabilitarParcelamentoAnual"
            v-if="(tomador || {}).Id"
          />
        </vs-tab>

        <vs-tab label="Filiais">
          <filiais
            :uniqueIdTomador="tomador.UniqueId"
            :dadosFiliais="tomador.Branches"
          />
        </vs-tab>

        <vs-tab
          label="Grupo Econômico"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum
                .GERENCIAR_TOMADORES_SEGURADORA,
              profileHelpers.menuFuncionalitiesEnum
                .VISUALIZAR_TOMADORES_SEGURADORA
            ])
          "
        >
          <grupo-economico :tomador="tomador" />
        </vs-tab>

        <vs-tab
          label="Regras Executadas"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum
                .GERENCIAR_TOMADORES_SEGURADORA,
              profileHelpers.menuFuncionalitiesEnum
                .VISUALIZAR_TOMADORES_SEGURADORA
            ])
          "
        >
          <regras-executadas
            :tomador="tomador"
            :personRisk="tomador.PersonRisk"
          />
        </vs-tab>

        <vs-tab label="Limites e Taxas">
          <limites-taxas :tomador="tomador" :personRisk="tomador.PersonRisk" />
        </vs-tab>

        <vs-tab
          label="Condições Comerciais"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum
                .GERENCIAR_TOMADORES_SEGURADORA,
              profileHelpers.menuFuncionalitiesEnum
                .VISUALIZAR_TOMADORES_SEGURADORA
            ])
          "
        >
          <CondicoesMercado
            :tomador="tomador"
            :personRisk="tomador.PersonRisk"
          />
        </vs-tab>

        <vs-tab
            label="CCG"
            v-if="profileHelpers.checkPermission([profileHelpers.menuFuncionalitiesEnum.GERENCIAR_CCG])
        ">
          <CCG v-if="tomador.Id" :tomador="tomador" :permissoesCCG="permissoesCCG"/>
        </vs-tab>

        <!-- <vs-tab label="Consórcio / SPE">
          <consorcio />
        </vs-tab>
        <vs-tab label="Boletos Pendentes">
          <boletos-pendentes />
        </vs-tab> -->

        <vs-tab label="Apólices">
          <apolices-tomador :tomador="tomador" v-if="tomador.Id" />
        </vs-tab>

        <vs-tab
          label="Sócios / Representantes"
          v-if="
            tomador.Id &&
              profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum
                  .GERENCIAR_TOMADORES_SEGURADORA,
                profileHelpers.menuFuncionalitiesEnum
                  .VISUALIZAR_TOMADORES_SEGURADORA
              ])
          "
        >
          <TableSocios v-if="tomador.UniqueId" :Socios="tomador.UniqueId" />
        </vs-tab>

        <vs-tab label="Documentos" v-if="profileHelpers.isInsurance() && profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_TELA_DE_DOCUMENTOS,
              ])">
          <broker-documents />
        </vs-tab>

        <vs-tab
          label="Histórico de Alterações"
          v-if="
            tomador.Id &&
              profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum
                  .GERENCIAR_TOMADORES_SEGURADORA,
                profileHelpers.menuFuncionalitiesEnum
                  .VISUALIZAR_TOMADORES_SEGURADORA
              ])
          "
        >
          <historico-alteracao :id="tomador.Id" />
        </vs-tab>
        <vs-tab
          label="Histórico de Consultas"
          v-if="
            tomador.Id &&
              profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum
                  .GERENCIAR_TOMADORES_SEGURADORA,
                profileHelpers.menuFuncionalitiesEnum
                  .VISUALIZAR_TOMADORES_SEGURADORA
              ])
          "
        >
        <HistoricoConsultasTomador v-if="exibirHistoricoConsultasTomadorPorBureau" :document="tomador.CpfCnpj"/>
        <historico-consultas v-else  :document="tomador.CpfCnpj" />
        </vs-tab>
        <!-- <vs-tab
          label="Histórico de Callbacks"
          v-if="tomador.Id &&
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum
                .GERENCIAR_TOMADORES_SEGURADORA,
              profileHelpers.menuFuncionalitiesEnum
                .VISUALIZAR_TOMADORES_SEGURADORA
            ])"
        >
          <historico-callbacks  :dados="tomador"  :type="'POLICY_HOLDER'"/>
        </vs-tab> -->
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";

import DadosGerais from "./components/DadosGerais.vue";
import HistoricoAlteracao from "./components/HistoricoAlteracao.vue";
import LimitesTaxas from "./components/LimitesTaxas.vue";
import CondicoesMercado from "./components/CondicoesMercado.vue";
import Documentos from "./components/Documentos";
import Consorcio from "./components/Consorcio.vue";
import BoletosPendentes from "./components/BoletosPendentes.vue";
import ApolicesTomador from "./components/ApolicesTomador.vue";
import Filiais from "./components/Filiais.vue";
import RegrasExecutadas from "./components/RegrasExecutadas.vue";
import GrupoEconomico from "./components/GrupoEconomico.vue";
import CCG from "@/views/tomador/components/CCG.vue"
import PerfilHelpers from "@/helpers/PerfilHelpers";
import TableSocios from "@/components/vs-table/Table-Socios.vue";
import HistoricoConsultas from "@/components/historico-consultas/HistoricoConsultas.vue";
import HistoricoConsultasTomador from "./components/HistoricoConsultasTomador.vue";
import HistoricoCallbacks from "../../components/historico-callback/index.vue";
import { writeFileAsync } from "xlsx";
import environment from "@/environment";

import commonProvider from "@/providers/common-provider.js";
import * as Permissoes from "@/utils/permissions.js";

export default {
  components: {
    breadcrumb: Breadcrumb,
    DadosGerais,
    LimitesTaxas,
    CCG,
    CondicoesMercado,
    "broker-documents": Documentos,
    Consorcio,
    BoletosPendentes,
    HistoricoCallbacks,
    ApolicesTomador,
    Filiais,
    RegrasExecutadas,
    GrupoEconomico,
    TableSocios,
    // HistoricoConsultas,
    // HistoricoAlteracao
    TableSocios,
    HistoricoConsultas,
    HistoricoAlteracao,
    HistoricoConsultasTomador
  },
  data() {
    return {
      tomadorUniqueIdParam: "",

      profileHelpers: PerfilHelpers,
      isExcelsior: false,
      isEssor: false,
      permissoesTomador: [],
      permissoesCCG: []
    };
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.carregarPagina();
      await this.obterPermissoes();
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "detalhe-tomador",
      url: window.location.href
    });

    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Excelsior = parseInt(environment.CompanyId.Excelsior);
    let Essor = parseInt(environment.CompanyId.Essor);
    this.isExcelsior = portalConfig.CompanyId == Excelsior ? true : false;
    this.isEssor = portalConfig.CompanyId == Essor ? true : false
  },
  destroyed() {
    this.updateTomador(undefined);
  },
  computed: {
    ...mapGetters("tomador-module", ["tomador"]),

    exibirHistoricoConsultasTomadorPorBureau() {
      return this.permissoesTomador.includes("ExibirHistoricoConsultasBureau");
    },

    exibirHabilitarParcelamentoAnual() {
      return this.permissoesTomador.includes("HabilitarParcelamentoAnual");
    },
  },
  methods: {
    ...mapActions("tomador-module", ["carregarTomador"]),

    ...mapMutations("tomador-module", ["updateTomador"]),

    async carregarPagina() {
      await this.$onpoint
        .loading(async () => {
          return await this.carregarTomador(this.$route.params.tomadorUniqueId)
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        })
        .then(() => {
          this.$forceUpdate();
        });
    },

    updateSupplementaryData(data) {
      let phones = [...this.tomador.Phones];
      phones[0] = data.phone;

      this.updateTomador({
        ...this.tomador,
        Location: data.location,
        Phones: phones
      });
    },

    async obterPermissoes() {
      this.permissoesTomador = [];
      try {
        this.permissoesTomador = await commonProvider.obterPermissoesModulo(Permissoes.Modulo.TOMADOR);
        this.permissoesCCG = await commonProvider.obterPermissoesModulo(Permissoes.Modulo.CCG);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    }
  }
};
</script>
<style lang="scss">
.perfil-tomador {
  .con-vs-tabs {
    display: flex;
  }

  .con-ul-tabs {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 250px;
    padding: 50px 20px;
    margin-bottom: 10px;
  }

  .line-vs-tabs {
    margin-top: 50px;
    margin-left: 20px;
  }

  .vs-tabs--li button.vs-tabs--btn {
    margin-top: 30px;
    width: auto;
  }

  .vs-tabs--ul {
    box-shadow: none;
    flex-direction: column;
  }

  .vs-tabs--li {
    padding-right: 15px;
  }

  .con-slot-tabs {
    background: white;
    border-radius: 10px;
    margin: 0 20px 10px 5px;
  }
}
</style>
