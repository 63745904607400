var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.consorcios.length > 0
        ? _c(
            "vs-table",
            {
              attrs: {
                data: _vm.consorcios,
                noDataText: "Nenhum consórcio encontrado.",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return _vm._l(data, function (tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr },
                          [
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].cnpj } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(data[indextr].cnpj) +
                                    "\n        "
                                ),
                              ]
                            ),
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].razaoSocial } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(data[indextr].razaoSocial) +
                                    "\n        "
                                ),
                              ]
                            ),
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].participacao } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(data[indextr].participacao) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      })
                    },
                  },
                ],
                null,
                false,
                3995131453
              ),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { "sort-key": "cnpj" } }, [
                    _vm._v("CNPJ"),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "razaoSocial" } }, [
                    _vm._v("Razão Social"),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "participacao" } }, [
                    _vm._v("% Participação"),
                  ]),
                ],
                1
              ),
            ],
            2
          )
        : [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col w-full md:w-1/2 mx-auto items-center my-32",
              },
              [
                _c("p", [
                  _c("span", { staticClass: "material-icons text-6xl" }, [
                    _vm._v("description"),
                  ]),
                ]),
                _c("p", [
                  _vm._v("Nenhum consórcio cadastrado para esse tomador."),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }