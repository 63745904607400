<template>
  <div class="pt-5 limites_taxas ">
    <div class="text-right" v-if="profileAllowed">
      <el-button type="primary" @click="openAdicionarPremio()" class=""
        >Adicionar condição
        <i
          class="onpoint-plus"
        ></i></el-button
      ><br /><br />
    </div>

    <template v-if="listaPremios.length > 0">
      <el-table :data="listaPremios" style="width: 100%">
        <el-table-column label="Modalidade">
          <template slot-scope="scope">
            <span style="margin-left: 10px">
        
            {{
              scope.row.ModalityName + " (" + scope.row.BranchName + ")" 
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Tipo" width="200px">
          <template slot-scope="scope">
            {{
              scope.row.MinInsurancePremium ? "Prêmio mínimo" : "Prêmio fixo"
            }}
          </template>
        </el-table-column>
        <el-table-column label="Valor" width="200px">
          <template slot-scope="scope">
            {{
              scope.row.MinInsurancePremium
                ? scope.row.MinInsurancePremium.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  })
                : scope.row.FixedInsurancePremium.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  })
            }}
          </template>
        </el-table-column>
        <el-table-column label="Ações" width="200px" v-if="profileAllowed">
          <template slot-scope="scope">
            <el-button size="mini" @click="editarPremio(scope.row)"
              >Editar</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deletarPremio(scope.row)"
              >Deletar</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center my-32">
        <i class="onpoint-file-text"></i>
        <p class="text-center">
          Não há registros desse tomador disponíveis para visualização.
        </p>
      </div>
    </template>

    <vs-popup
      class="editar-grupo-modalidade"
      :title="'Adicionar condição'"
      :active.sync="modalAdicionarPremio"
    >
      <div class="vx-row">
        <el-alert
          title="Selecione a modalidade e digite um prêmio fixo ou mínimo."
          type="warning"
          :closable="false"
        >
        </el-alert>
        <span class="mt-3">Modalidade</span>
        <el-select
          class="mt-3"
          v-model="modalidadeSelecionada"
          filterable
          placeholder="Escolha a modalidade"
        >
          <el-option
            v-for="item in groupList"
            :key="item.Id"
            :label="item.Name + ' (' + item.ModalityGroup.Branch.Name + ')'"
            :value="item.Id"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </div>
      <div class="vx-row mt-6">
        <div class="vx-col w-1/2">
          <p>Prêmio fixo</p>
          <currency-input
            @change="premioMinimo = null"
            v-model="premioFixo"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
        </div>
        <div class="vx-col w-1/2">
          <p>Prêmio Mínimo</p>
          <currency-input
            @change="premioFixo = null"
            v-model="premioMinimo"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
        </div>
      </div>
      <div class="vs-row mt-5">
        <el-alert
          v-if="errorModal"
          title="Erro"
          type="error"
          :description="errorMensage"
          :closable="false"
          show-icon
        >
        </el-alert>
        <div class="vx-col text-right mt-6">
          <vs-button @click="adicionarCondicao()">
            Salvar alterações
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="editar-grupo-modalidade"
      :title="'Editar Condição  da  Modalidade - '"
      :active.sync="editarGrupoModalidadePopup"
    >
      <div class="vx-row">
        <span class="mt-3">Modalidade</span>
        <el-select
          disabled
          class="mt-3"
          v-model="modalidadeSelecionada"
          filterable
          placeholder="Escolha a modalidade"
        >
          <el-option
            v-for="item in groupList"
            :key="item.Id"
            :label="item.Name + ' (' + item.ModalityGroup.Branch.Name + ')'"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="vx-row mt-6">
        <div class="vx-col w-1/2">
          <p>Prêmio fixo</p>
          <currency-input
            @change="premioMinimo = null"
            v-model="premioFixo"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
        </div>
        <div class="vx-col w-1/2">
          <p>Prêmio Mínimo</p>
          <currency-input
            @change="premioFixo = null"
            v-model="premioMinimo"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
        </div>
      </div>
      <div class="vs-row mt-5">
        <el-alert
          v-if="errorModal"
          title="Erro"
          type="error"
          :description="errorMensage"
          :closable="false"
          show-icon
        >
        </el-alert>
        <div class="vx-col text-right mt-6">
          <vs-button @click="salvarCondicao()">
            Salvar alterações
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import axiosInstance from "@/axios";
import * as Utils from "@/utils/utils";
import { mask, TheMask } from "vue-the-mask";
import _ from "lodash";
import { mapActions } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "limites-taxas",
  directives: { mask },
  components: {
    TheMask
  },
  props: {
    tomador: {
      type: Object,
      required: true
    },
    personRisk: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editarModalidade: null,
      errorMensage: "",
      errorModal: false,
      modalidadeSelecionada: null,
      ramos: [],
      modalidadeList: [],
      groupList: [],
      modalityGroupList: [],
      mudaPremio: false,
      modalAdicionarPremio: false,
      listaPremios: [],
      editarGrupoModalidadePopup: false,
      grupoModalidade: {},
      limites: [],
      limitesTotais: {},
      premioMinimo: null,
      premioFixo: null,
      bailLimitData: null,
      hasBail: false,
      limteDisponivelAprovado: 0,
      limteTotalAprovado: 0,
      limiteTotalUtilizado: 0,

      perfilHelpers:PerfilHelpers
    };
  },
computed:{
profileAllowed(){
return this.perfilHelpers.checkPermission([
              this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA
            ])
}
},
  async mounted() {
    await this.getRamosModality();
  },
  watch: {
    modalAdicionarPremio() {
      if (this.modalAdicionarPremio == false) {
        this.modalidadeSelecionada = null;
        this.premioMinimo == null;
        this.premioFixo == null;
        this.errorMensage = "";
        this.errorModal = false;
      }
    },
    editarGrupoModalidadePopup() {
      if (this.editarGrupoModalidadePopup == false) {
        this.modalidadeSelecionada = null;
        this.premioMinimo == null;
        this.premioFixo == null;
        this.errorMensage = "";
        this.errorModal = false;
      }
    }
  },

  methods: {
    ...mapActions("seguradora-module", [
      "getAllBranch",
      "getModalitiesByBranch"
    ]),
    async getRamosModality() {
      await this.$onpoint.loading(async () => {
        await this.getAllBranch().then(response => {
          this.ramos = response;
          response.map(async branch => {
           await this.$onpoint.loading(async () => {
              return await this.getModalitiesByBranch(branch.Id).then(
                async modalitys => {
                  this.modalityGroupList[branch.Id] = modalitys.filter(
                    (v, i, a) =>
                      a.findIndex(
                        t => t.ModalityGroup.Name === v.ModalityGroup.Name
                      ) === i
                  );
                  await modalitys.map(async grupo => {
                    if(!grupo.IsDeleted){
                      this.groupList.push(grupo);
                    }
                  });
                  this.$forceUpdate();
                  this.carregarCondicoesMercado();
                }
              );
            });
          });
        });
      });
    },
    openAdicionarPremio() {
      this.modalAdicionarPremio = true;
    },
    editarPremio(row) {
      this.editarModalidade = row;
      this.modalidadeSelecionada = row.ModalityId;
      this.premioFixo = row.FixedInsurancePremium;
      this.premioMinimo = row.MinInsurancePremium;
      this.editarGrupoModalidadePopup = true;
    },
    replaceItem(item) {
      return item.toString().replace(".", ",");
    },
    editarGrupoModalidade(item) {
      this.grupoModalidade = Utils.deepClone(item);
      this.editarGrupoModalidadePopup = true;
    },
    virgulaPorPonto(value) {
      let dados = value;
      dados.RiskRate = dados.RiskRate.toString().replace(",", ".");
      return dados;
    },
    deletarPremio(row) {
      this.$confirm("Deseja apagar condição ?", "Aviso!", {
        confirmButtonText: "Apagar",
        cancelButtonText: "Cancelar",
        type: "warning"
      })
        .then(() => {
          this.$onpoint.loading(() => {
            return axiosInstance({
              method: "post",
              url: `api/PolicyHolder/DeletePolicyHolderMarketTerms?PolicyHolderMarketTermsId=${row.Id}`
            })
              .then(data => {
                this.$onpoint.successModal("Condição excluida com sucesso!");
                return this.carregarCondicoesMercado();
              })
              .catch(error => {
                this.$onpoint.errorModal(error.response.data.Errors);
              });
          });
        })
        .catch(() => {});
    },
    //UpdatePolicyHolderLimitAndRate
    adicionarCondicao() {
      if (this.modalidadeSelecionada == null) {
        this.errorModal = true;
        this.errorMensage = "Modalidade obrigatória, escolha uma modalidade!";
        return false;
      }
      if (this.premioFixo == null && this.premioMinimo == null) {
        this.errorModal = true;
        this.errorMensage =
          "Prêmio obrigatório, por favor escolha um prêmio mínimo ou fixo!";
        return false;
      }

      this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: "/api/PolicyHolder/AddNewPolicyHolderMarketTerms",
          data: {
            PolicyHolderUniqueId: this.tomador.UniqueId,
            ModalityId: this.modalidadeSelecionada,
            MinInsurancePremium: this.premioMinimo,
            FixedInsurancePremium: this.premioFixo
          }
        })
          .then(data => {
            this.grupoModalidade = {};
            this.modalAdicionarPremio = false;
            this.$onpoint.successModal("Condição  criada  com sucesso!");
            return this.carregarCondicoesMercado();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    salvarCondicao() {
      if (this.premioFixo == null && this.premioMinimo == null) {
        this.errorModal = true;
        this.errorMensage =
          "Prêmio obrigatório, por favor escolha um prêmio mínimo ou fixo!";
        return false;
      }

      this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: "/api/PolicyHolder/UpdatePolicyHolderMarketTerms",
          data: {
            Id: this.editarModalidade.Id,
            ModalityId: this.modalidadeSelecionada,
            MinInsurancePremium: this.premioMinimo,
            FixedInsurancePremium: this.premioFixo
          }
        })
          .then(data => {
            this.grupoModalidade = {};
            this.editarGrupoModalidadePopup = false;
            this.$onpoint.successModal("Condição  Atualizada  com sucesso!");
            return this.carregarCondicoesMercado();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    carregarCondicoesMercado() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/PolicyHolder/GetPolicyHolderMarketTerms?PolicyHolderUniqueId=${this.tomador.UniqueId}`
          )
          .then(response => {
            this.listaPremios = response.data.Response;
            var grupo = this.groupList;
            this.listaPremios.map(async item => {
              grupo.map(async condicao => {
                if (item.ModalityId == condicao.Id) {
                  condicao.disabled = true;
                }
              });
            });
            this.groupList = grupo;
          });
      });
    }
  }
};
</script>
<style lang="scss">
.el-select .el-input__inner {
  border-radius: 25px !important;
  padding: 15px 15px !important;
  height: 50px !important;
}
.el-select {
  width: 100%;
}
.el-select-dropdown {
  z-index: 9999999999999999999999999999 !important;
}
.input-currency {
  border: none;
  background: transparent;
  color: inherit;
  font-family: inherit;
  text-align: center;
}
</style>

<style lang="scss" scoped>
.editar-grupo-modalidade {
  z-index: 52900 !important;

  .vs-popup {
    padding: 5px 20px !important;
  }
}

.limites_taxas {
  td {
    border-top: solid 1px rgba(0, 0, 0, 0.27);
  }

  td {
    padding: 30px 10px !important;

    .info {
      color: rgba(var(--vs-warning), 1);
      font-size: 0.8em;
    }
  }

  .vs-con-tbody {
    border: none !important;
    background: white !important;
  }

  .vs-con-table td {
    text-align: center;
  }

  .vs-table-text {
    justify-content: center !important;
    text-align: center;
  }

  .tr-table-state-warning {
    td {
      color: initial;
      opacity: 0.7;
    }
  }

  .disabled {
    background-color: rgba(239, 239, 239, 0.3);
  }
  .con-vs-loading {
    z-index: 100 !important;
  }
  .buscar-cep {
    height: 47px;
  }

  #proposta-loading {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wizard-tab-content {
    background: white !important;
    margin: 2em 0 0;
    min-height: 130px !important;
  }

  .onpoint-plus::before{
    position: relative;
    top: 1px;
    font-size: 16px;
  }
  .onpoint-file-text::before{  
    font-size: 56px;
  }
}
</style>
