<template>
  <div class="representatives-container">
    <h4 class="font-bold text-primary">
      <slot name="header"></slot>
    </h4>

    <div class="parts">
      <div class="header">
        <ul
          v-if="tipoParteName === 'Fiador Pessoa Jurídica'"
          class="header-content-pj"
        >
          <li>CNPJ</li>
          <li>Razão Social</li>
          <li>CPF do Representante</li>
          <li>Nome do Representante</li>
          <li>E-mail do Representante</li>
          <li></li>
        </ul>
        <ul v-else class="header-content">
          <li>CPF</li>
          <li>Nome</li>
          <li>E-mail</li>
          <li></li>
        </ul>
      </div>
      <ul class="part" v-for="(parte, index) in partes" :key="index">
        <div class="part-wrapper">
          <li
            class="part-infos"
            v-if="
              tipoParteName === 'Representante Legal' ||
                tipoParteName === 'Fiador Pessoa Física' ||
                tipoParteName === 'Testemunha' ||
                tipoParteName === 'Seguradora'
            "
          >
            <div
              class="part-infos-status"
              :class="`status-${parte.TipoParteStatusNome.toLowerCase()}`"
              @mouseenter="mostrarTooltip = true"
              @mouseleave="mostrarTooltip = false"
            >
              <div
                v-if="tipoParteName === 'Seguradora' ? showAssingSecurity : true"
                class="part-infos-status-notice"
                :class="`status-${parte.TipoParteStatusNome.toLowerCase()}`"
              >
                {{ parte.TipoParteStatusNome }}
              </div>
            </div>

            <p>{{ parte.CPFMask }}</p>
            <!-- <p>{{ parte.CPFMask }}</p> -->
            <div class="divider"></div>
            <p>{{ parte.Nome }}</p>
            <div class="divider"></div>
            <p v-if="parte.Email" class="part-infos-email">
              {{ parte.Email }}
            </p>
            <p v-else class="part-infos-warning">
              Nenhum e-mail cadastrado
            </p>
            <div class="divider"    v-if="tipoParteName !== 'Seguradora'"></div>
            <ListaAcoes
              v-if="tipoParteName !== 'Seguradora'"
              :parte="parte"
              :permissoes="permissoes"
              :conjugeHabilitado="conjugeHabilitado"
              :contratoEspecificacoes="contratoEspecificacoes"
              @edicaoDadosSelecionado="encaminharEdicaoDados(parte)"
              @cadastrarConjugeSelecionado="encaminharAdicaoConjuge(parte)"
              @reenviarEmailSelecionado="encaminharReenviarEmail(parte)"
              @copiarLinkAssinaturaSelecionado="
                encaminharCopiarLinkAssinatura(parte)
              "
              @excluirRepresentanteSelecionado="
                encaminharExcluirRepresentante(parte)
              "
            />
          </li>

          <li
            v-if="parte.Conjuge && conjugeHabilitado"
            class="part-infos-spouse"
          >
            <div
              class="part-infos-spouse-status"
              :class="`status-${parte.TipoParteStatusNome.toLowerCase()}`"
              @mouseenter="mostrarTooltip = true"
              @mouseleave="mostrarTooltip = false"
            >
              <div
                class="part-infos-spouse-status-notice"
                :class="`status-${parte.TipoParteStatusNome.toLowerCase()}`"
              >
                {{ parte.TipoParteStatusNome }}
              </div>
            </div>

            <p>{{ parte.Conjuge.CPF }}</p>
            <div class="divider" ></div>

            <div class="part-infos-spouse-status-notice-tooltip">
              <el-tooltip
                class="item"
                effect="light"
                content="Cônjuge"
                placement="top"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
              <p>{{ parte.Conjuge.Nome }}</p>
            </div>

            <div class="divider"></div>
            <p v-if="parte.Conjuge.Email" class="part-infos-spouse-email">
              {{ parte.Conjuge.Email }}
            </p>
            <p v-else class="part-infos-spouse-warning">
              Nenhum e-mail cadastrado
            </p>
            <div class="divider"></div>
            <ListaAcoes
              :parte="parte.Conjuge"
              :permissoes="permissoes"
              :contratoEspecificacoes="contratoEspecificacoes"
              @edicaoDadosSelecionado="encaminharEdicaoDados(parte.Conjuge)"
              @reenviarEmailSelecionado="encaminharReenviarEmail(parte)"
              @copiarLinkAssinaturaSelecionado="
                encaminharCopiarLinkAssinatura(parte.Conjuge)
              "
              @excluirRepresentanteSelecionado="
                encaminharExcluirRepresentante(parte.Conjuge)
              "
            />
          </li>

          <li
            class="part-infos-pj"
            v-if="parte.TipoParteName === 'Fiador Pessoa Jurídica'"
          >
            <div
              class="part-infos-pj-status"
              :class="`status-${parte.TipoParteStatusNome.toLowerCase()}`"
              @mouseenter="mostrarTooltip = true"
              @mouseleave="mostrarTooltip = false"
            >
              <div
                class="part-infos-pj-status-notice"
                :class="`status-${parte.TipoParteStatusNome.toLowerCase()}`"
              >
                {{ parte.TipoParteStatusNome }}
              </div>
            </div>

            <p>{{ parte.CNPJMask }}</p>
            <div class="divider"></div>
            <p>{{ parte.NomeEmpresa }}</p>
            <div class="divider"></div>
            <p>{{ parte.CPFMask}}</p>
            <div class="divider"></div>
            <p>{{ parte.Nome }}</p>
            <div class="divider"></div>
            <p v-if="parte.Email" class="part-infos-pj-email">
              {{ parte.Email }}
            </p>
            <p v-else class="part-infos-pj-warning">
              Nenhum e-mail cadastrado
            </p>
            <ListaAcoes
              :parte="parte"
              :permissoes="permissoes"
              :contratoEspecificacoes="contratoEspecificacoes"
              @edicaoDadosSelecionado="encaminharEdicaoDados(parte)"
              @reenviarEmailSelecionado="encaminharReenviarEmail(parte)"
              @copiarLinkAssinaturaSelecionado="
                encaminharCopiarLinkAssinatura(parte)
              "
              @excluirRepresentanteSelecionado="
                encaminharExcluirRepresentante(parte)
              "
            />
          </li>
        </div>
      </ul>
    </div>

    <div class="add-rep" v-if="tipoParteName !== 'Seguradora'">
      <el-button
        type="primary"
        class="add-rep-btn"
        :disabled="
          !deveExibirAdicionarParte(tipoParteName) ||
            contratoEspecificacoes.StatusId !== 1
        "
        @click="encaminharAdicaoParte(tipoParteId, tipoParteName)"
      >
        <i class="el-icon-user"></i>
        <slot name="button"></slot>
      </el-button>
    </div>
  </div>
</template>

<script>
import ListaAcoes from "@/components/CCG/components/ListaAcoes.vue";

export default {
  name: "tabela-representantes",
  props: {
    tipoParteId: {
      type: Number,
      required: true
    },
    partes: {
      type: Array,
      default: () => []
    },
    permissoes: {
      type: Array,
      default: () => []
    },
    contratoEspecificacoes: {
      type: Object,
      required: true
    },
    conjugeHabilitado: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ListaAcoes
  },
  data() {
    return {
      mostrarTooltip: false,
      showAssingSecurity:false
    };
  },
  created(){
    this.rulesAssignContractAutentique()
  },
  computed: {
    tipoParteName() {
      return this.partes.length > 0 ? this.partes[0].TipoParteName : "";      
    },

    podeAdicionarRepresentante() {
      return this.permissoes.includes("PodeIncluirRepresentante");
    },

    podeAdicionarFiadorPF() {
      return this.permissoes.includes("PodeIncluirFiadorPessoaFisica");
    },

    podeAdicionarFiadorPJ() {
      return this.permissoes.includes("PodeEditarFiadorPessoaJuridica");
    }
  },
  methods: {
    deveExibirAdicionarParte(podeAdicionar) {
      switch (podeAdicionar) {
        case "Representante Legal":
          return this.podeAdicionarRepresentante;
        case "Fiador Pessoa Física":
          return this.podeAdicionarFiadorPF;
        case "Fiador Pessoa Jurídica":
          return this.podeAdicionarFiadorPJ;
        default:
          return true;
      }
    },

    encaminharAdicaoParte(tipoParteId, tipoParteName) {
      this.$emit("adicionarParteSelecionado", {
        tipoParteId: tipoParteId,
        tipoParteName: tipoParteName
      });
    },

    encaminharAdicaoConjuge(parte) {
      this.$emit("adicionarConjugeSelecionado", {
        tipoParteId: 5,
        parteId: parte.ParteId,
        contratoId: parte.ContratoId
      });
    },

    encaminharEdicaoDados(parte) {
      this.$emit("edicaoDadosSelecionado", {
        parte: parte
      });
    },

    encaminharReenviarEmail(parte) {
      this.$emit("reenviarEmailSelecionado", {
        parteId: parte.ParteId,
        contratoId: parte.ContratoId
      });
    },

    encaminharCopiarLinkAssinatura(parte) {
      this.$emit("copiarLinkAssinaturaSelecionado", {
        parte: parte
      });
    },

    encaminharExcluirRepresentante(parte) {
      this.$emit("excluirRepresentanteSelecionado", {
        parteId: parte.ParteId,
        contratoId: parte.ContratoId,
        tipoParteName: parte.TipoParteName
      });
    },

    rulesAssignContractAutentique(){

      if( this.tipoParteName === 'Seguradora'){
        const noUserAutentique=['mapfre']
        const todosRepresentantesTomadorPrecisamAssinar=['essor',"excelsior", "sombrero","newe"]
        const todosFiadoresPrecisamAssinar = ['essor',"excelsior", "sombrero","newe"]
        const seguradoraPrecisaAssinar = ["excelsior", "sombrero","newe"]

        const security = JSON.parse(localStorage.getItem("lsApplicationConfig")).dns

        let canShowStatus={}

        if(noUserAutentique.includes(security)) return;

        if(todosRepresentantesTomadorPrecisamAssinar.includes(security)){
          let assinaturas=[]
          this.ccg.RepresentantesLegais.forEach((representante) => {
            return representante.TipoParteStatusNome === 'Assinado' ? assinaturas.push('ok'):assinaturas.push('false')
          })
          assinaturas.length > 0 && !assinaturas.includes('false') ? canShowStatus.representantesTomador = true : canShowStatus.representantesTomador = false
        }

        if(todosFiadoresPrecisamAssinar.includes(security)){
          let assinaturas=[]
          const todosFiadores = this.ccg.FiadoresPessoaFisica.concat(this.ccg.FiadoresPessoaJuridica)

          todosFiadores.forEach((representante)=>{
            return representante.TipoParteStatusNome === 'Assinado' ? assinaturas.push('ok'):assinaturas.push('false')
          })

          assinaturas.length > 0 && !assinaturas.includes('false') ? canShowStatus.fiadores = true : canShowStatus.fiadores = false

        }

        if(seguradoraPrecisaAssinar.includes(security)){
          this.ccg.Seguradora[0].TipoParteStatusNome === 'Assinado' ? canShowStatus.seguradora = true : canShowStatus.seguradora = false
        }



        return this.showAssingSecurity= !Object.values(canShowStatus).includes(false)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.representatives-container {
  background: #ededed;
  width: 100%;
  margin-top: 36px;
  border-radius: 8px;
  padding: 28px;

  h4 {
    font-size: 18px;
    margin: 8px 0 0 8px;
  }

  .parts {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 36px;

    .header {
      &-content {
        width: 100%;
        padding: 0 8px;
        color: #4b4b4b;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        padding: 4px 8px 16px;
        display: grid;
        grid-template-columns: 1.35fr 2.55fr 1.9fr 1.1fr;

        & li {
          margin-left: 16px;
        }
      }

      &-content-pj {
        width: 100%;
        padding: 0 8px;
        color: #4b4b4b;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        padding: 4px 8px 16px;
        display: grid;
        grid-template-columns: 1fr 1.5fr 1.3fr 1.8fr 1.6fr 0.9fr;

        & li {
          margin-left: 16px;
        }
      }
    }

    .part {
      padding: 0;
      margin: 0;
      width: 100% !important;

      &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 16px;
        padding: 8px;
        background-color: #dfdfdf;
        border-radius: 8px;
      }

      &-infos {
        width: 100%;
        background: #ffffff;
        padding: 12px 24px;
        display: grid;
        grid-template-columns: 6px 1fr 0.2fr 2.2fr 0.2fr 2fr 0fr 0fr;
        align-items: center;
        border-radius: 8px;
        position: relative;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.04);

        &:hover {
          background-color: rgba(231, 245, 255, 1);
        }

        &-email {
          word-break: break-word;
        }

        &-status {
          &::before {
            position: absolute;
            content: " ";
            left: 0px;
            top: 50%;
            width: 6px;
            height: 100%;
            transform: translate(0%, -50%);
            cursor: pointer;
            border-radius: 8px 0 0 8px;
          }

          &-notice {
            position: absolute;
            transform: translate(0%, -50%);
            border-radius: 0 36px 36px 0;
            padding: 10px 16px 10px 34px;
            left: 0;
            font-size: 12px;
            font-weight: 700;
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
            opacity: 0;

            &:hover {
              opacity: 1;
            }
          }
        }

        &-warning {
          color: rgba(255, 159, 67, 1);
        }
      }

      &-infos-pj {
        width: 100%;
        background: #ffffff;
        padding: 12px 24px;
        display: grid;
        grid-template-columns: 6px 0.8fr 0.2fr 2.7fr 0.2fr 2fr 0.2fr 3.2fr 0.2fr 2.4fr 0.2fr 0.4fr;
        align-items: center;
        border-radius: 8px;
        position: relative;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.04);

        &:hover {
          background-color: rgba(231, 245, 255, 1);
        }

        &-email {
          word-break: break-word;
        }

        &-status {
          &::before {
            position: absolute;
            content: " ";
            left: 0px;
            top: 50%;
            width: 6px;
            height: 100%;
            transform: translate(0%, -50%);
            cursor: pointer;
            border-radius: 8px 0 0 8px;
          }

          &-notice {
            position: absolute;
            transform: translate(0%, -50%);
            border-radius: 0 36px 36px 0;
            padding: 10px 16px 10px 34px;
            left: 0;
            font-size: 12px;
            font-weight: 700;
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
            opacity: 0;

            &:hover {
              opacity: 1;
            }
          }
        }

        &-warning {
          color: rgba(255, 159, 67, 1);
        }
      }

      &-infos-spouse {
        width: 98%;
        background: #ffffff;
        margin-top: 8px;
        padding: 12px 24px;
        display: grid;
        grid-template-columns: 6px 1fr 0.2fr 2fr 0.2fr 1.957fr 0fr 0fr;
        align-items: center;
        border-radius: 8px;
        position: relative;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.04);

        &:hover {
          background-color: rgba(231, 245, 255, 1);
        }

        &-email {
          word-break: break-word;
        }

        &-status {
          &::before {
            position: absolute;
            content: " ";
            left: 0px;
            top: 50%;
            width: 6px;
            height: 100%;
            transform: translate(0%, -50%);
            cursor: pointer;
            border-radius: 8px 0 0 8px;
          }

          &-notice {
            position: absolute;
            transform: translate(0%, -50%);
            border-radius: 0 36px 36px 0;
            padding: 10px 16px 10px 34px;
            left: 0;
            font-size: 12px;
            font-weight: 700;
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
            opacity: 0;

            &:hover {
              opacity: 1;
            }

            &-tooltip {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              & i {
                margin-right: 12px;
                font-weight: bold;
                color: rgba(var(--vs-primary), 1) !important;
              }
            }
          }
        }

        &-warning {
          color: rgba(255, 159, 67, 1);
        }
      }

      .divider {
        width: 2px;
        height: 56px;
        background-image: linear-gradient(
          to bottom,
          #ccc 33%,
          transparent 33%,
          transparent 66%,
          #ccc 66%
        );
        background-size: 100% 6px;
        margin: 0 12px;
      }
    }
  }

  .add-rep {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgba(142, 141, 142, 1);
    border-radius: 50px;

    &-btn {
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      padding: 12px 18px;

      i {
        margin-right: 8px;
      }
    }
  }
}

.status-criado {
  background-color: rgba(var(--vs-primary), 1) !important;
  color: #fff;

  &::before {
    background-color: rgba(var(--vs-primary), 1) !important;
  }
}

.status-assinado {
  background-color: #00a925;
  color: #fff;

  &::before {
    background-color: #00a925;
  }
}

.status-aguardando {
  background-color: #faff00;
  color: #000000;

  &::before {
    background-color: #faff00;
  }
}
</style>
