<template>
  <section class="mt-4">
    <div class="table-container">
      <template v-if="listaApolices.length > 0">
        <vs-table
          class="table-list"
          :data="listaApolices"
          :hoverFlat="true"
          noDataText="Nenhuma apólice encontrada"
          maxHeight="72vh"
        >
          <template slot="thead">
            <vs-th>Número</vs-th>
            <vs-th class="text-center">Contém endosso?</vs-th>
            <vs-th sort-key="InsuredName">Segurado</vs-th>
            <vs-th sort-key="PolicyStatusId" class="text-center">Status</vs-th>
            <vs-th sort-key="IssueDate" class="text-center">Emissão</vs-th>
            <vs-th sort-key="EndDate" class="text-center">Vencimento</vs-th>
            <vs-th class="text-center">Vigência</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].PolicyNumber" class="text-center">
                {{ data[indextr].PolicyNumber || "-" }}
              </vs-td>

              <vs-td :data="item.EndorsementCount" class="text-center">
                <span class="flex"
                  >{{ item.EndorsementCount > 0 ? "Sim" : "Não" }}
                  <vs-button
                    radius
                    class="visualizar-endosso"
                    color="primary"
                    type="flat"
                    icon="remove_red_eye"
                    v-if="item.EndorsementCount > 0"
                    @click="showEndorsement(item.PolicyUniqueId)"
                  ></vs-button>
                </span>
              </vs-td>

              <vs-td :data="data[indextr].InsuredName" class="text-center">
                {{ data[indextr].InsuredName }}
              </vs-td>

              <vs-td :data="item.PolicyStatusId" class="text-center">
                <vs-chip
                  v-if="item.PolicyStatusId > 0"
                  :color="getStatus(item.PolicyStatusId) | setColorStatus"
                >
                  {{ getStatus(item.PolicyStatusId) }}
                </vs-chip>
                <template v-else>-</template>
              </vs-td>

              <vs-td :data="item.IssueDate" class="text-center">
                {{
                  item.IssueDate ? $utils.dateToDDMMYYYY(item.IssueDate) : "-"
                }}
              </vs-td>

              <vs-td :data="item.EndDate" class="text-center">
                {{ item.EndDate ? $utils.dateToDDMMYYYY(item.EndDate) : "-" }}
              </vs-td>

              <vs-td class="text-center">
                <vs-progress
                  :percent="
                    getPercent(
                      item.StartDate,
                      item.EndDate,
                      item.PolicyStatusId
                    )
                  "
                  :height="8"
                  :color="
                    getPercentColor(
                      getPercent(
                        item.StartDate,
                        item.EndDate,
                        item.PolicyStatusId
                      )
                    )
                  "
                  >primary</vs-progress
                >
              </vs-td>

              <vs-td>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-apolice"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu class="btn-action-apolice-options">
                    <vs-dropdown-item>
                      <p
                        class="text-base px-6 py-2"
                        @click="
                          downloadApoliceClick(
                            data[indextr].OriginalProposalUniqueId
                          )
                        "
                      >
                        <span>Visualizar apólice</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_TOMADORES_SEGURADORA,
                          profileHelpers.menuFuncionalitiesEnum
                            .VISUALIZAR_TOMADORES_SEGURADORA,
                          profileHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_TOMADORES,
                          profileHelpers.menuFuncionalitiesEnum
                            .VISUALIZAR_TOMADORES
                        ]) && data[indextr].BilletCount > 0
                      "
                      divider
                      @click="
                        parcelasModal = true;
                        $refs.parcelasModalComponent.carregarParcelas(
                          data[indextr].OriginalProposalUniqueId
                        );
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Visualizar boleto(s)</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      divider
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .DOWNLOAD_DO_LAUDO_DE_EMISSAO
                        ]) && data[indextr].PolicyStatusId == 2
                      "
                      @click="
                        downloadLaudoEmissaoClick(
                          data[indextr].OriginalProposalUniqueId
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Visualizar laudo de emissão</span>
                      </p>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                      divider
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .CANCELAR_APOLICE_MANUALMENTE
                        ]) && data[indextr].PolicyStatusId == 2
                      "
                      @click="
                        apoliceSelecionadaCancelamento =
                          data[indextr].PolicyNumber;
                        abrirPopupCancelamentoApolice();
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Cancelar apólice manualmente</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <i class="onpoint-file-text"></i>
          <p>Nenhuma apólice encontrada para este tomador</p>
        </div>
      </template>
    </div>

    <!-- Popup cancelamento de apólice -->
    <el-dialog
      width="24%"
      :visible.sync="popupCancelamentoApolice"
      class="policy-cancellation-popup"
    >
      <div class="policy-cancellation-popup-content">
        <div class="policy-cancellation-popup-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
            alt="Ícone de cancelamento"
            width="24"
            height="24"
          />
          <h3>
            Cancelar apólice
          </h3>
        </div>

        <div class="policy-cancellation-popup-content-body">
          <el-alert
            type="warning"
            center
            :closable="false"
            class="policy-cancellation-popup-content-body-alert"
          >
            O cancelamento da apólice é uma ação permanente, não podendo ser revertida após sua confirmação!
          </el-alert>

          <div class="policy-cancellation-popup-content-body-action">
            <p v-if="apoliceSelecionadaCancelamento" class="policy-cancellation-popup-content-body-policy">Número da apólice: {{ apoliceSelecionadaCancelamento }}</p>
            <p class="policy-cancellation-popup-content-body-advice">
              Para efetuar o cancelamento, digite o número da apólice no campo abaixo:
            </p>
            <input
              class="policy-cancellation-popup-content-body-input"
              @keyup="verificarChaveCancelamentoApolice"
              v-model="chaveCancelamentoApolice"
            />
          </div>
        </div>
      </div>

      <div
        slot="footer"
        class="dialog-footer policy-cancellation-popup-footer"
      >
        <p class="policy-cancellation-popup-footer-question">Deseja continuar?</p>

        <div class="policy-cancellation-popup-footer-btns">
          <el-button type="primary" :disabled="desabilitarCancelamentoApolice" :class="{desabilitado: desabilitarCancelamentoApolice}" class="policy-cancellation-popup-footer-btn" @click="internalCancelPolicy()"
            >Sim, cancelar apólice</el-button
          >

          <el-button class="policy-cancellation-popup-footer-btn" @click="fecharPopupCancelamentoApolice"
            >Não</el-button
          >
        </div>
      </div>
    </el-dialog>

    <listar-parcelas-popup
      ref="parcelasModalComponent"
      :parcelasModal="parcelasModal"
      @fecharParcelasModal="parcelasModal = false"
      :autoLoad="false"
    />
  </section>
</template>

<script>
import ListarParcelasPopup from "@/views/propostas/components/visualizacao/ListarParcelasPopup.vue";

import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "@/mixins/optionsStaticMixin";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "apolices-tomador",
  components: { ListarParcelasPopup },
  props: {
    tomador: {
      type: Object,
      required: true
    }
  },
  mixins: [optionsStaticMixin],
  mounted() {
    this.listarApolicesTomador();
  },
  data() {
    return {
      parcelasModal: false,
      listaApolices: [],
      apoliceSelecionadaCancelamento: null,
      profileHelpers: PerfilHelpers,
      popupCancelamentoApolice: false,
      chaveCancelamentoApolice: "",
      desabilitarCancelamentoApolice: true,
    };
  },
  computed: {
    ...mapGetters("endosso-module", ["endossoList"])
  },
  methods: {
    ...mapActions("apolice-module", [
      "searchApolicesTomador",
      "downloadApolice",
      "postInternalCancelPolicy"
    ]),
    ...mapActions("propostas-module", [
      "downloadBoleto",
      "downloadLaudoEmissao"
    ]),

    listarApolicesTomador() {
      this.$onpoint.loading(() => {
        return this.searchApolicesTomador(this.tomador.UniqueId)
          .then(response => {
            this.listaApolices = response;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    downloadApoliceClick(propostaUniqueId) {

      let payload = {
        propostaUniqueId: propostaUniqueId,
        endorsement: false
      };

      this.$onpoint.loading(() => {
        return this.downloadApolice(payload)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async downloadBoletoClick(propostaUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.downloadBoleto(propostaUniqueId)
          .then(linksToDownload => {
            //deve-se desabilitar o bloqueio de popups no navegador, senão apenas o primeiro item será baixado -> Lucaxx
            linksToDownload.forEach(element => {
              window.open(element);
            });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async downloadLaudoEmissaoClick(propostaUniqueId) {
      await this.$onpoint.loading(async() => {
        return await  this.downloadLaudoEmissao(propostaUniqueId)
          .then(urlToDownloadIssuanceReport => {
            window.open(urlToDownloadIssuanceReport, "_self");
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    internalCancelPolicy() {
      this.$onpoint.loading(() => {
        this.popupCancelamentoApolice = false;
        return this.postInternalCancelPolicy(
          this.apoliceSelecionadaCancelamento
        )
          .then(response => {
            this.listarApolicesTomador();
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    goToSolicitacaoEndosso(policyUniqueId) {
      this.$router.push({
        name: "solicitacao-endosso-apolice",
        params: {
          policyUniqueId: policyUniqueId,
          type: "CANCELAMENTO"
        }
      });
    },

    getStatus(statusId) {
      const status = this.policyStatusOptionsMixin.filter(status => {
        if (status.id == statusId) return status;
      });

      return status[0].name;
    },

    getPercent(startDate, endDate, statusId) {
      if (!endDate || !startDate || statusId == 3) return 100;

      const timeNow = new Date().getTime();
      const timeStart = new Date(startDate).getTime();
      const timeEnd = new Date(endDate).getTime();

      if (timeStart > timeNow || statusId == 1) return 0;
      else if (timeNow > timeEnd) return 100;
      else {
        return ((timeEnd - timeNow) * 100) / timeEnd;
      }
    },

    getPercentColor(percent) {
      if (percent < 33) return "success";
      else if (percent >= 33 && percent < 66) return "warning";
      else return "danger";
    },

    showEndorsement(policyUniqueId) {
      let routeData = this.$router.resolve({
        name: "detalhar-apolice",
        params: { policyUniqueId }
      });
      window.open(routeData.href, "_blank");
    },

    verificarChaveCancelamentoApolice(event){
      this.desabilitarCancelamentoApolice = this.chaveCancelamentoApolice === this.apoliceSelecionadaCancelamento ?  false : true;
    },

    abrirPopupCancelamentoApolice(){
      this.chaveCancelamentoApolice = "";
      this.popupCancelamentoApolice = true;
      this.desabilitarCancelamentoApolice = true;
    },

    fecharPopupCancelamentoApolice(){
      this.apoliceSelecionadaCancelamento = null;
      this.popupCancelamentoApolice = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--tbody {
    border: none !important;
  }

  ::v-deep .vs-table--thead {
    tr {
      background: transparent;

      th.text-center .vs-table-text {
        justify-content: center;
      }
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    // border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }

  .btn-action-apolice {
    cursor: pointer;
  }
}

.btn-action-apolice-options {
  width: 300px;

  ::v-deep .vs-dropdown--menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.vs-table--tbody-table
  .tr-values.hoverFlat:not(.activeEdit):not(.is-expand):hover {
  opacity: unset;
}

.visualizar-endosso {
  width: 3px !important;
  height: 3px !important;
  margin-left: 7px;
  transform: scale(1.5);
}

.policy-cancellation-popup {
  &-content {
    &-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid #747474;

      & h3 {
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px 0;

      &-alert {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        word-break: break-word;
      }

      &-action{
        margin-top: 36px;
      }

      &-advice {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #79747e;
        word-break: break-word;
      }

      &-policy{
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #79747e;
        margin-bottom: 16px;
        word-break: break-word;
      }

      &-input {
        width: 100%;
        height: 40px;
        display: inline-block;
        color: #606266;
        margin-top: 16px;
        outline: 0;
        border-radius: 4px;
        background-color: #FFF;
        border: 1px solid #DCDFE6;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
      }

      &-input:focus{
        border-color: #409EFF;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;

    &-question {
      text-align: center;
      width: 100%;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      color: #79747e;
      word-break: break-word;
      margin-bottom: 28px;
    }

    &-btns{
      display: flex;
      justify-content: center;
      align-items: center;

      &-btn {
        font-weight: 600;
      }
    }

  }
}

.desabilitado{
  background: rgba(var(--vs-primary), 0.2) !important;
  border-color: rgba(var(--vs-primary), 0) !important;
}

.onpoint-file-text::before{  
  font-size: 56px;
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
