<template>
  <div class="pt-5 limites_taxas">
    <!--
    <div class="infos-container flex flex-row w-full mb-6">
      <div class="infos-porte flex flex-col w-1/4 pr-4">
        <p class="font-semibold">Porte Comportamental</p>
        <vs-input :value="personRisk.Class" size="large" class="w-full" disabled="true" />
      </div>
      <div class="infos-classe flex flex-col w-1/3">
        <p class="font-semibold">Classe de Risco - Comportamental</p>
        <vs-input :value="personRisk.RiskClassScore" size="large" class="w-full" disabled="true" />
      </div>
    </div> -->
    <template v-if="limites.length > 0 && canViewLimits">
      <vs-table :data="limites" noDataText="Nenhum Limite e Taxas encontrado.">
        <template slot="thead">
          <vs-th>
            <span class="w-full text-center">Modalidades</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">
              Limite Aprovado <span class="text-danger">*</span></span
            >
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Limite Utilizado</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Limite Disponível</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center"
              >Taxa <br />
              Segurado privado/público</span
            >
          </vs-th>
          <vs-th
            v-if="
              profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA
              ])
            "
          >
            Ações
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].ModalityGroupName">
              <div class="flex">
                <span class="ml-20">{{ data[indextr].ModalityGroupName }}</span>
                <vx-tooltip :text="data[indextr].ModalityGroupDescription">
                  <span class="material-icons text-warning text-sm ml-2"
                    >info</span
                  >
                </vx-tooltip>
              </div>
            </vs-td>

            <vs-td
              :data="data[indextr].LimitRevised"
              class="text-primary font-semibold"
            >
              <p
                v-if="data[indextr].CanViewLimit || podeVisualizarLimitesTaxas"
              >
                {{ data[indextr].LimitRevised | maskCurrency }}
              </p>

              <br />
              <small
                class="text-danger"
                v-if="
                  data[indextr].ChangedManually && !profileHelpers.isInsurance()
                "
                >* Limite alterado pela seguradora</small
              >
            </vs-td>

            <vs-td
              :data="data[indextr].UsedLimit"
              class="text-primary font-semibold"
            >
              <p
                v-if="data[indextr].CanViewLimit || podeVisualizarLimitesTaxas"
              >
                {{ data[indextr].UsedLimit | maskCurrency }}
              </p>
            </vs-td>

            <vs-td
              :data="data[indextr].AvailableLimit"
              class="text-primary font-semibold"
            >
              <p
                v-if="data[indextr].CanViewLimit || podeVisualizarLimitesTaxas"
              >
                {{ data[indextr].AvailableLimit | maskCurrency }}
              </p>
            </vs-td>

            <vs-td :data="data[indextr].RiskRate">
              <p
                v-if="podeVisualizarLimitesTaxas || data[indextr].CanViewLimit"
              >
                {{ replaceItem(data[indextr].RiskRate) }}% /
                {{ replaceItem(data[indextr].RiskRate) }}%
              </p>
            </vs-td>
            <vs-td
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA
                ])
              "
            >
              <vs-button
                type="filled"
                color="primary"
                @click="editarGrupoModalidade(data[indextr])"
                icon="edit"
              >
                Editar
              </vs-button>
            </vs-td>
          </vs-tr>
          <vs-tr state="warning">
            <vs-td>
              <p class="font-bold">Limite Total</p>
            </vs-td>
            <vs-td class="font-bold">
              {{ limteTotalAprovado | maskCurrency }}
            </vs-td>
            <vs-td class="font-bold">
              {{ limiteTotalUtilizado | maskCurrency }}
            </vs-td>
            <vs-td class="font-bold">
              {{ limteDisponivelAprovado | maskCurrency }}
            </vs-td>
            <vs-td></vs-td>
            <vs-td v-if="podeVisualizarLimitesTaxas"></vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <p class="px-32 pt-4 pb-12" v-if="canRequestLimitsAndRatesReview">
        <span class="text-warning">*</span>
        Limite pré-aprovado e provisorio, pode estar aquém da capacidade do
        tomador. Para aprovação do limite completo e definitivo é preciso a
        inclusão da demanda e o envio da documentação financeira
      </p>
    </template>
    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center my-32">
        <i class="onpoint-file-text"></i>
        <p class="text-center">
          Não há limites desse tomador disponíveis para visualização.
        </p>
      </div>
    </template>
    <template
      v-if="
        (bailLimitData != {} &&
          bailLimitData != null &&
          hasBail &&
          profileHelpers.isInsurance() && !mostrarDadosFiancaCorretor)
      "
    >
      <vs-table noDataText="">
        <template slot="header">
          <h4 class="px-8 pb-4 pt-6">
            <b>Acúmulo externo</b>
          </h4>
        </template>
        <template slot="thead">
          <vs-th>
            <span class="w-full text-center">Grupo</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Limite</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Acúmulo</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Taxa</span>
          </vs-th>
          <vs-th v-if="profileHelpers.isInsurance()">
            <span class="w-full text-center"> Ações </span>
          </vs-th>
        </template>

        <template>
          <vs-tr>
            <vs-td>
              <div class="w-full text-center">
                <span>Fiança Locatícia</span>
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <currency-input
                  class="input-currency text-primary font-semibold"
                  v-model="bailLimitData.LimCredito"
                  disabled="true"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <currency-input
                  class="input-currency text-primary font-semibold"
                  v-model="bailLimitData.Acumulo"
                  disabled="true"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <p>
                  {{ bailLimitData.TaxaRisco }}% /
                  {{ bailLimitData.TaxaRisco }}%
                </p>
              </div>
            </vs-td>
            <vs-td
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA
                ])
              "
            >
              <vs-button
                type="filled"
                color="primary"
                @click="updatePolicyHolderBailLimit()"
              >
                Atualizar
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <p class="px-32 pt-4 pb-12" v-if="mostrarDadosFiancaCorretor">
        <span class="text-warning">**</span>
        Cotações e Emissões do Ramo Fiança Locatícias não podem ser realizadas
        através desta plataforma. Os limites e taxas disponibilizados são apenas
        informativos. Para maiores informações entre em contato com o time comercial desta Seguradora.
      </p>
    </template>

    <template
      v-if="
        (bailLimitData != {} &&
          bailLimitData != null &&
          hasBail &&
          profileHelpers.isInsurance()) || mostrarDadosFiancaCorretor
      "
    >
      <vs-table noDataText="">
        <template slot="header">
          <h4 class="px-8 pb-4 pt-6">
            <b>Acúmulo externo</b>
          </h4>
        </template>
        <template slot="thead">
          <vs-th>
            <span class="w-full text-center">Modalidades</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Limite aprovado</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">limite utilizado</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">limite disponível</span>
          </vs-th>
          <vs-th>
            <span class="w-full text-center">Taxa <br/> segurado publico/privado</span>
          </vs-th>
          <vs-th v-if="profileHelpers.isInsurance()">
            <span class="w-full text-center"> Ações </span>
          </vs-th>
        </template>

        <template>
          <vs-tr>
            <vs-td>
              <div class="w-full text-center">
                <span>Fiança Locatícia</span>
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <currency-input
                  class="input-currency text-primary font-semibold"
                  v-model="bailLimitData.LimCredito"
                  disabled="true"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <currency-input
                  class="input-currency text-primary font-semibold"
                  v-model="bailLimitData.Acumulo"
                  disabled="true"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <currency-input
                  class="input-currency text-primary font-semibold"
                  v-model="bailLimitData.Disponivel"
                  disabled="true"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="w-full text-center">
                <p>
                  {{ bailLimitData.TaxaRisco }}% /
                  {{ bailLimitData.TaxaRisco }}%
                </p>
              </div>
            </vs-td>
            <vs-td
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA
                ])
              "
            >
            <vs-button
                type="filled"
                color="primary"
                @click="editarGrupoModalidadeFianca(bailLimitData)"
                icon="edit"
              >
                Editar
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </template>

    <vs-popup
      class="editar-grupo-modalidade"
      :title="
        'Editar Grupo de Modalidade - Fiança Locaticia '
      "
      :active.sync="editarGrupoModalidadeFiancaPopup"
    >
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <p>Limite Aprovado</p>
          <currency-input
            v-model="grupoModalidadeFianca.LimCredito"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            label="Taxa"
            v-model="grupoModalidadeFianca.TaxaRisco"
            size="large"
          />
        </div>
      </div>
      <div class="vs-row mt-5">
        <div class="vx-col text-right">
          <vs-button @click="salvarGrupoModalidadeFianca()">
            Salvar alterações
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="editar-grupo-modalidade"
      :title="
        'Editar Grupo de Modalidade - ' + grupoModalidade.ModalityGroupName
      "
      :active.sync="editarGrupoModalidadePopup"
    >
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <p>Limite Aprovado</p>
          <currency-input
            v-model="grupoModalidade.LimitRevised"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            label="Taxa"
            v-model="grupoModalidade.RiskRate"
            size="large"
          />
        </div>
      </div>
      <div class="vs-row mt-5">
        <div class="vx-col text-right">
          <vs-button @click="salvarGrupoModalidade()">
            Salvar alterações
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import axiosInstance from "@/axios";
import * as Utils from "@/utils/utils";
import { mask, TheMask } from "vue-the-mask";

import PerfilHelpers from "@/helpers/PerfilHelpers";

import environment from "../../../environment";

export default {
  name: "limites-taxas",
  directives: { mask },
  components: {
    TheMask
  },
  props: {
    tomador: {
      type: Object,
      required: true
    },
    personRisk: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editarGrupoModalidadePopup: false,
      editarGrupoModalidadeFiancaPopup: false,
      grupoModalidade: {},
      grupoModalidadeFianca: {},
      limites: [],
      limitesTotais: {},
      bailLimitData: {},
      hasBail: false,
      limteDisponivelAprovado: 0,
      limteTotalAprovado: 0,
      limiteTotalUtilizado: 0,
      Newe: false,
      profileHelpers: PerfilHelpers
    };
  },
  mounted() {
    this.carregarLimitesTomador();
  },
  computed: {
    canRequestLimitsAndRatesReview: {
      get() {
        return (this.tomador || {}).CanRequestLimitsAndRatesReview;
      }
    },

    canViewLimits: {
      get() {
        return (
          this.profileHelpers.checkPermission([
        this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA,
        this.profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_TOMADORES_SEGURADORA
      ]) ||
          (this.tomador || {}).CanViewLimitsAndRates
        );
      }
    },

    mostrarDadosFiancaCorretor() {
      var portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
      var Newe = parseInt(environment.CompanyId.Newe)
      return this.Newe = portalConfig.CompanyId == Newe  ? true : false ;
    },

    podeVisualizarLimitesTaxas() {
      return this.profileHelpers.checkPermission([
        this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA,
        this.profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_TOMADORES_SEGURADORA
      ]);
    }
  },
  methods: {
    replaceItem(item) {
      return item.toString().replace(".", ",");
    },
    editarGrupoModalidade(item) {
      this.grupoModalidade = Utils.deepClone(item);
      this.editarGrupoModalidadePopup = true;
    },
    editarGrupoModalidadeFianca(bailLimitData) {
      this.grupoModalidadeFianca = new Utils.deepClone(bailLimitData);
      this.editarGrupoModalidadeFiancaPopup = true;
    },
    virgulaPorPonto(value){
      let dados = value;
      dados.RiskRate = dados.RiskRate.toString().replace(",", ".");
      return dados
    },
    //UpdatePolicyHolderLimitAndRate
    salvarGrupoModalidade() {
      this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: "/api/PolicyHolder/UpdatePolicyHolderLimitAndRate",
          data: this.virgulaPorPonto(this.grupoModalidade)
        })
          .then(data => {
            this.grupoModalidade = {};
            this.editarGrupoModalidadePopup = false;
            this.$onpoint.successModal("Limite atualizado com sucesso!");
            return this.carregarLimitesTomador();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    salvarGrupoModalidadeFianca() {

      let data = {PolicyHolderUniqueId: this.tomador.UniqueId, CreditLimitBail: this.grupoModalidadeFianca.LimCredito, RiskRateBail: this.grupoModalidadeFianca.TaxaRisco.toString().replace(",", ".")}

      this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: "/api/PolicyHolder/UpdatePolicyHolderBailLimitAndRate",
          data: data
        })
          .then(data => {
            this.grupoModalidadeFianca = {};
            this.editarGrupoModalidadeFiancaPopup = false;
            this.$onpoint.successModal("Limite atualizado com sucesso!");
            return this.carregarLimitesTomador();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    carregarLimitesTomador() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/PolicyHolder/GetLastPolicyHolderLimitsAndRates?PolicyHolderUniqueId=${this.tomador.UniqueId}`
          )
          .then(response => {
            this.limitesTotais = response.data.Response;
            this.limites = this.limitesTotais.LimitAndRates;
            this.limteDisponivelAprovado = this.limitesTotais.MaxAvailableLimit;
            this.limteTotalAprovado = this.limitesTotais.MaxApprovedLimit;
            this.limiteTotalUtilizado = this.limitesTotais.TotalUsedLimit;
            this.bailLimitData = this.limitesTotais.BailLimit;
            this.hasBail = this.limitesTotais.HasBail;
            this.limitesTotais.PolicyHolderLimitsAndRates.map(item => {
              item.RiskRate = item.RiskRate.toString().replace(".", ",");
            });
          });
      });
    },
    updatePolicyHolderBailLimit() {
      this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: `/api/PolicyHolder/UpdatePolicyHolderBailLimit?PolicyHolderUniqueId=${this.tomador.UniqueId}`,
          data: []
        })
          .then(data => {
            this.$onpoint.successModal("Limite atualizado com sucesso!");
            return this.carregarLimitesTomador();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    }
    /*  calculaTotais (limites) {
      this.limteDisponivelAprovado = 0
      this.limteTotalAprovado = 0

      limites.reduce((acc, limite) => {
        this.limteDisponivelAprovado += Number(limite.AvailableLimit)
        return this.limteDisponivelAprovado
      }, 0)

      limites.reduce((acc, limite) => {
        this.limteTotalAprovado += Number(limite.AvailableLimit)
        return this.limteTotalAprovado
      }, 0)
    } */
  }
};
</script>
<style lang="scss">
.input-currency {
  border: none;
  background: transparent;
  color: inherit;
  font-family: inherit;
  text-align: center;
}
</style>

<style lang="scss" scoped>
.editar-grupo-modalidade {
  z-index: 52900 !important;

  .vs-popup {
    padding: 5px 20px !important;
  }
}

.limites_taxas {
  td {
    border-top: solid 1px rgba(0, 0, 0, 0.27);
  }

  td {
    padding: 30px 10px !important;

    .info {
      color: rgba(var(--vs-warning), 1);
      font-size: 0.8em;
    }
  }

  .vs-con-tbody {
    border: none !important;
    background: white !important;
  }

  .vs-con-table td {
    text-align: center;
  }

  .vs-table-text {
    justify-content: center !important;
    text-align: center;
  }

  .tr-table-state-warning {
    td {
      color: initial;
      opacity: 0.7;
    }
  }

  .disabled {
    background-color: rgba(239, 239, 239, 0.3);
  }
  .con-vs-loading {
    z-index: 100 !important;
  }
  .buscar-cep {
    height: 47px;
  }

  #proposta-loading {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wizard-tab-content {
    background: white !important;
    margin: 2em 0 0;
    min-height: 130px !important;
  }
}
.onpoint-file-text::before{  
  font-size: 56px;
}
</style>
